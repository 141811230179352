<div (click)="redirectToFlightDetails()" class="flight-card">
  <span class="flight-card-left-content shrink">
    <div class="departure-date date">
      {{ flight?.departureDate }}
    </div>
    <div class="departure-station station">
      {{ flight?.departureStation }}
    </div>
    <div class="gate-number">
      {{ flight?.departureGate }}
    </div>
    <div class="departure-time time">
      {{ flight?.departureTime }}
    </div>
  </span>
  <span class="flight-card-middle-content">
    <div *ngIf="displayFlightNum" class="flight-num">
      {{ flight?.airlineCode }} {{ flight?.flightNum }}
    </div>
    <div class="flight-time-container">
      <span class="material-icons-outlined icon left-icon"
        >arrow_circle_right</span
      >
      <span class="line"></span>
      <span class="flight-time">{{ flight?.travelTime }}</span>
      <span class="line"></span>
      <span class="material-icons-outlined icon right-icon"
        >arrow_circle_right</span
      >
    </div>
  </span>
  <span class="flight-card-right-content shrink">
    <div class="arrival-date date">
      {{ flight?.arrivalDate }}
    </div>
    <div class="arrival-station station">
      {{ flight?.arrivalStation }}
    </div>
    <div class="gate-number">
      {{ flight?.arrivalGate }}
    </div>
    <div class="arrival-time time">
      {{ flight?.arrivalTime }}
    </div>
  </span>
</div>

<div *ngIf="flight && flight.layOverInfo && flight.layOverInfo.hotelInfo">
  <mat-card class="layover-card">
    <div class="content-width display-apart" (click)="toggleLayover(isOpen)">
      <div>
        <span class="font-medium font-weight-big">Layover</span>
      </div>
      <div class="layover-label-right">
        <div class="font-small flight-layover-info">
          <span>{{ flight.layOverInfo.layOverAirport }}</span>
          <span> ({{ flight.layOverInfo.layOverTime | HoursConverter }})</span>
        </div>
        <div class="circle-arrow">
          <span
            [ngClass]="{ 'rotate-arrow': isOpen }"
            class="material-icons-outlined"
          >
            expand_circle_down
          </span>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isOpen" class="layover-info-container">
      <div class="hotel-info font-small">
        <span class="label">Hotel</span>
        <span class="font-weight-big">
          {{ flight.layOverInfo.hotelInfo.name }}</span
        >
        <span
          >{{ flight.layOverInfo.hotelInfo.address1 }}
          {{ flight.layOverInfo.hotelInfo.address2 }}</span
        >
        <span
          >{{ flight.layOverInfo.hotelInfo.city }},
          {{ flight.layOverInfo.hotelInfo.state }}
          {{ flight.layOverInfo.hotelInfo.zip }}</span
        >
        <span class="font-italic">{{
          flight.layOverInfo.hotelInfo.phone
        }}</span>
      </div>
      <mat-divider class="layover-info-divider"></mat-divider>
      <div *ngIf="flight.layOverInfo?.limoInfo" class="limo-info font-small">
        <span class="label"> Limo </span>
        <span class="font-weight-big">{{
          flight.layOverInfo?.limoInfo?.limoCompany
        }}</span>
        <span class="font-italic">{{
          flight.layOverInfo?.limoInfo?.limoPhoneNumber
        }}</span>
      </div>
    </ng-container>
  </mat-card>
</div>

<div *ngIf="flight && flight.crewMembersWithSameLayover">
  <mat-card class="layover-card">
    <div
      class="content-width crew-info-header display-apart"
      (click)="toggleCrew(isCrewOpen)"
    >
      <div>
        <span class="font-medium font-weight-big">Crew staying here</span>
      </div>
      <div class="layover-label-right">
        <div class="font-small flight-layover-info"></div>
        <div class="circle-arrow">
          <span
            [ngClass]="{ 'rotate-arrow': isCrewOpen }"
            class="material-icons-outlined"
          >
            expand_circle_down
          </span>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isCrewOpen" class="layover-info-container">
      <div
        class="font-small crew-info"
        *ngFor="let crew of flight.crewMembersWithSameLayover"
      >
        <div *ngIf="crew.empNum !== positionIsOpen">
          <div>
            <span class="label"
              >{{ crew.fltPosition }} / {{ crew.empNum }}</span
            >
          </div>
          <span class="font-weight-big">
            {{ crew.firstName }} {{ crew.lastName }}</span
          >
        </div>
      </div>
      <mat-divider class="layover-info-divider"></mat-divider>
    </ng-container>
  </mat-card>
</div>
