<ng-container
  *ngIf="!nextActivity.isSignedIn && !nextActivity.isSignInEligible"
>
  <section class="alert-box">
    <div class="alert-group">
      <span class="alert-text"
        >Sign in
        {{ nextActivity.signInTime | date: ' dMMM H:mm' | uppercase }}
        at {{ nextActivity.signInAirport }}</span
      >
    </div>
  </section>
</ng-container>
<ng-container *ngIf="nextActivity.isSignInEligible && !nextActivity.isSignedIn">
  <section class="alert-box info" (click)="signInForTrip()">
    <div class="alert-group">
      <mat-icon class="info-icon">info</mat-icon>
      <span class="alert-text">{{ signInText }}</span>
    </div>
    <div class="alert-end-icon">
      <mat-icon>arrow_forward</mat-icon>
    </div>
  </section>
</ng-container>
<ng-container *ngIf="nextActivity.isSignedIn">
  <section class="alert-box success">
    <div class="alert-group">
      <mat-icon>check_circle</mat-icon>
      <span class="alert-text">{{ tripSignInConstants.SignedInTripText }}</span>
    </div>
  </section>
</ng-container>
