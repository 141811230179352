/**
 * Get users element
 * API payload - preflight document
 */
export class PreflightDocumentModel {
  appSessionId: string;
  siteMinderEmployeeId: number;
  businessUnit: string;
  constructor(
    appSessionId: string,
    siteMinderEmployeeId: number,
    businessUnit: string
  ) {
    this.appSessionId = appSessionId;
    this.siteMinderEmployeeId = siteMinderEmployeeId;
    this.businessUnit = businessUnit;
  }
}
