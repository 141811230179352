import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.services';
import {
  AdminUserModel,
  CreateUserModel,
} from 'src/app/routes/admin/models/user';
import { environment } from 'src/environments/environment';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { User } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getUserState } from 'src/app/shared/store/reducers';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private crudUrl = `${environment.apiBaseURL}/v1/userManagement`;
  userObs: Observable<UserState> = this.store.select(getUserState);

  user: User = new User();

  constructor(
    private httpService: HttpService,
    private store: Store<{ user: UserState }>
  ) {
    this.userObs.subscribe({
      next: (userObs) => {
        this.user = userObs.user;
      },
    });
  }

  public getUserData() {
    const payload: AdminUserModel = new AdminUserModel(
      'browser',
      'GETALL',
      this.user.employeeNumber ?? 0
    );

    return this.httpService.postService(this.crudUrl, payload);
  }

  public crudUser(
    createUserModel: CreateUserModel,
    requestType: string
  ): Observable<boolean> {
    createUserModel.appSessionId = 'browser';
    createUserModel.requestType = requestType;
    createUserModel.siteMinderEmployeeId = this.user.employeeNumber;

    return this.httpService
      .postWithResponseService(this.crudUrl, createUserModel)
      .pipe(
        map((x) => {
          return x.body.success;
        })
      );
  }
}
