import { props, createAction } from '@ngrx/store';
export const getPreflightDataAction = createAction('[Auth] Get Preflight Data');

export const getPreflightDataSuccessAction = createAction(
  '[API] Get Preflight Data success',
  props<{ preflightData: any }>()
);

export const getPreflightDataFailureAction = createAction(
  '[API] Get Preflight Data Failure',
  props<{ error?: string }>()
);

export type getPreflightDataActionsUnion = ReturnType<
  | typeof getPreflightDataAction
  | typeof getPreflightDataSuccessAction
  | typeof getPreflightDataFailureAction
>;
