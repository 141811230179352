import { GroupFlightsArray } from './group-array-result';

export class InfoIndicators {
  rightStartIndicator: boolean | null;
  postFlightInspectionIndicator: boolean | null;
  flightKeysFlight: boolean | null;

  constructor(
    rightStartIndicator: string | null,
    postFlightInspectionIndicator: boolean | null,
    flightkeysFlight: boolean | null
  ) {
    this.rightStartIndicator = rightStartIndicator === 'Y' ? true : false;
    this.postFlightInspectionIndicator = postFlightInspectionIndicator;
    this.flightKeysFlight = flightkeysFlight;
  }
}

export interface CrewMembersWithSameLayover {
  firstName: string;
  lastName: string;
  empNum: string;
  fltPosition: string;
}

export class HotelInfo {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  fax?: string;
  name: string;
  phone: string;
  state?: string;
  zip?: string;
}

export class LimoInfo {
  limoCompany: string;
  limoPhoneNumber: string;
  limoInstructions?: string;
}

export class LayOverInfo {
  hotelInfo: HotelInfo | null;
  limoInfo: LimoInfo | null;
  layOverAirport: string;
  layOverTime: any;
  constructor(
    hotelInfo: HotelInfo | null,
    limoInfo: LimoInfo | null,
    layOverAirport: string,
    layOverTime: any
  ) {
    (this.hotelInfo = hotelInfo),
      (this.limoInfo = limoInfo),
      (this.layOverAirport = layOverAirport),
      (this.layOverTime = layOverTime);
  }
}

export class FlightSearchResult {
  airlineCode: string;
  flightNum: string;
  departureDate: string;
  departureStation: string;
  departureTime: string;
  travelTime: string;
  arrivalDate: string;
  arrivalStation: string;
  arrivalTime: string;
  noseNum: string | null;
  flightOriginationDate: string | null;
  infoIndicators: InfoIndicators;
  flightDate?: string | null;
  departureGate?: string;
  arrivalGate?: string;
  layOverInfo?: LayOverInfo;
  crewMembersWithSameLayover?: CrewMembersWithSameLayover[];
  groupFlights?: GroupFlightsArray;
  constructor(
    flightNum: string,
    departureDate: string,
    departureStation: string,
    departureTime: string,
    travelTime: string,
    arrivalDate: string,
    arrivalStation: string,
    arrivalTime: string,
    airlineCode: string,
    noseNum: string | null = null,
    flightOriginationDate: string | null,
    infoIndicators: InfoIndicators,
    flightDate?: string | null,
    departureGate?: string,
    arrivalGate?: string,
    layOverInfo?: LayOverInfo,
    crewMembersWithSameLayover?: CrewMembersWithSameLayover[],
    groupFlights?: GroupFlightsArray
  ) {
    this.airlineCode = airlineCode;
    this.flightNum = flightNum;
    this.departureDate = departureDate;
    this.departureTime = departureTime;
    this.travelTime = travelTime;
    this.arrivalDate = arrivalDate;
    this.arrivalTime = arrivalTime;
    this.departureStation = departureStation;
    this.arrivalStation = arrivalStation;
    this.noseNum = noseNum;
    this.flightOriginationDate = flightOriginationDate;
    this.infoIndicators = infoIndicators;
    this.flightDate = flightDate;
    this.departureGate = departureGate;
    this.arrivalGate = arrivalGate;
    this.layOverInfo = layOverInfo;
    this.crewMembersWithSameLayover = crewMembersWithSameLayover;
    this.groupFlights = groupFlights;
  }
}
