<div class="content-size">
  <mat-grid-list cols="5" rowHeight="fit" class="grid-height" gutterSize="20px">
    <mat-grid-tile colspan="2">
      <div class="grid-card grid-sequence-card">
        <app-sequence-card [flightResult]="flightResult"></app-sequence-card>
      </div>
    </mat-grid-tile>
    <mat-grid-tile
      colspan="3"
      class="grid-align-center overflow-visible grid-flight-cards"
    >
      <div class="grid-card" *ngIf="groupFlights">
        <div *ngFor="let flights of groupFlights">
          <div>
            <div [ngClass]="{ today: flights.isToday }" class="group-dates">
              <div
                [ngClass]="{ 'group-dates-today': flights.isToday }"
                class="group-dates-types"
              >
                <div>
                  <span class="groups-dates-dates">{{
                    flights.date | date: 'MMM'
                  }}</span>
                  <span class="group-dates-day">{{
                    flights.date | date: 'd'
                  }}</span>
                </div>
              </div>
              <div class="group-dates-weekday">
                <span>{{ flights.date | DateConverter }} </span>
              </div>
            </div>
            <div *ngFor="let flight of flights.flights">
              <div class="flight-cards-container">
                <app-flight-info-card
                  class="content-width flight-card-body"
                  [flight]="flight"
                ></app-flight-info-card>
              </div>
            </div>
          </div>
          <mat-divider class="sequence-divider-first"></mat-divider>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
