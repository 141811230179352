<div *ngIf="flightResult">
  <mat-card class="sequence-card">
    <mat-card-content>
      <div class="display-apart sequence-card-title">
        <div class="display-side">
          <button
            mat-icon-button
            class="sequence-card-common-icon"
            (click)="backToLanding()"
          >
            <mat-icon class="sequence-card-common-icon"
              >arrow_backward</mat-icon
            >
          </button>
          <span class="sequence-number">
            SEQ {{ flightResult.sequenceNumber }}</span
          >
        </div>
      </div>

      <mat-divider class="sequence-divider-first"></mat-divider>

      <div class="display-apart sequence-card-body">
        <div class="display-apart">
          <div class="sequence-card-body-grid">
            <div>
              <span class="sequence-card-common-font-small">Sign In</span>
            </div>
            <div>
              <span class="sequence-card-common-font-medium"
                >{{ flightResult.signInTime | date: 'HH:mm' }} /{{
                  flightResult.signInTime | date: 'd'
                }}</span
              >
            </div>
          </div>
          <div>
            <div>
              <span class="sequence-card-common-font-small">Release Time</span>
            </div>
            <div>
              <span class="sequence-card-common-font-medium"
                >{{ flightResult.releaseTime | date: 'HH:mm' }} /{{
                  flightResult.releaseTime | date: 'd'
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="display-apart">
          <div class="sequence-card-body-grid">
            <div>
              <span class="sequence-card-common-font-small">Days(Legs)</span>
            </div>
            <div>
              <span class="sequence-card-common-font-medium"
                >{{ flightResult.noOfDaysInSequence }}({{
                  flightResult.numberOfLegsPerDay
                }})</span
              >
            </div>
          </div>
          <div>
            <div>
              <span class="sequence-card-common-font-small">Credit</span>
            </div>
            <div>
              <span class="sequence-card-common-font-medium">{{
                flightResult.credit | HoursConverter
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <mat-divider class="sequence-divider-first"></mat-divider>
      <div class="trip-sign-in-container">
        <app-trip-sign-in></app-trip-sign-in>
      </div>
      <div></div>
    </mat-card-content>
  </mat-card>
</div>
