<ng-container *ngIf="flightDuty && flightDuty.flightLegs.length > 0">
  <ng-container *ngFor="let leg of flightDuty.flightLegs; let i = index">
    <div class="card-list-divider">
      <mat-divider></mat-divider>
    </div>
    <div class="flex-row" (click)="onClickFlightLeg(leg)">
      <div class="column-left">
        <div class="card-content">
          <mat-card-title-group>
            <mat-card-subtitle>
              {{ leg.departureStation }} TO
              {{ leg.arrivalStation }}
            </mat-card-subtitle>
          </mat-card-title-group>
          <mat-card-title-group>
            <mat-card-title
              >{{ leg.airlineCode }} {{ leg.flightNumber }}</mat-card-title
            >
          </mat-card-title-group>
        </div>
      </div>
      <div class="coloumn-right align-self-center">
        <mat-card-actions align="end">
          <mat-card-title *ngIf="leg.actual">
            {{ leg.actual.departureDateTime.localTime | date: 'HH:mm' }} -
            {{ leg.actual.arrivalDateTime.localTime | date: 'HH:mm' }}
          </mat-card-title>
          <mat-card-title *ngIf="!leg.actual">
            {{ leg.scheduled.departureDateTime.localTime | date: 'HH:mm' }} -
            {{ leg.scheduled.arrivalDateTime.localTime | date: 'HH:mm' }}
          </mat-card-title>
          <mat-icon>arrow_forward</mat-icon>
        </mat-card-actions>
      </div>
    </div>
    <ng-container *ngIf="leg.hotelInfo && leg.limoInfo">
      <div class="card-list-divider">
        <mat-divider></mat-divider>
      </div>
      <section class="layover-card-group">
        <app-flight-info
          (onClickLayoverData)="toggleLayover($event, leg)"
          [flightLeg]="leg"
          [title]="'Layover'"
          [layoverData]="{
            layoverInMinutes: flightDuty.layoverInMinutes,
            layOverAirport: flightDuty.layOverAirport
          }"
        ></app-flight-info>
        <div *ngIf="leg.isLayoverOpen">
          <div class="card-list-divider">
            <mat-divider></mat-divider>
          </div>
          <div class="layover-info">
            <mat-card-subtitle>HOTEL</mat-card-subtitle>
            <div class="layover-info-content">
              <mat-card-title>{{ leg.hotelInfo.name }}</mat-card-title>
              <mat-card-title
                >{{ leg.hotelInfo.address1 }}
                {{ leg.hotelInfo.address2 }}</mat-card-title
              >
              <mat-card-title
                >{{ leg.hotelInfo.city }} {{ leg.hotelInfo.state }}
                {{ leg.hotelInfo.zip }}</mat-card-title
              >
              <mat-card-title>{{ leg.hotelInfo.phone }}</mat-card-title>
            </div>
          </div>
          <div class="card-list-divider">
            <mat-divider></mat-divider>
          </div>
          <div class="layover-info">
            <mat-card-subtitle>LIMO</mat-card-subtitle>
            <div class="layover-info-content">
              <mat-card-title>{{ leg.limoInfo.limoCompany }}</mat-card-title>
              <mat-card-title>{{
                leg.limoInfo.limoPhoneNumber
              }}</mat-card-title>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-container
      *ngIf="
        isFFDEligible(leg) && (nextActivity.isSignedIn || leg.legIndex > 1)
      "
    >
      <section
        *ngIf="!confirmFFD(leg)"
        class="alert-box info"
        (click)="executeFfdCommand(leg, 'confirmFFD')"
      >
        <div class="alert-group">
          <mat-icon class="info-icon">info</mat-icon>
          <span class="alert-text">Confirm FFD</span>
        </div>
        <div class="alert-end-icon">
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </section>
      <section *ngIf="confirmFFD(leg)" class="alert-box success">
        <div class="alert-group">
          <mat-icon>check_circle</mat-icon>
          <span class="alert-text">{{
            fitForDutyConstants.confirmedText
          }}</span>
        </div>
      </section>
    </ng-container>
    <ng-container *ngIf="isSignFlightPlanEligible(leg)">
      <section
        class="alert-box info"
        *ngIf="!confirmFlightPlanSign(leg)"
        (click)="executeFfdCommand(leg, 'signFlightPlan')"
      >
        <div class="alert-group">
          <mat-icon class="info-icon">info</mat-icon>
          <span class="alert-text">{{ flightPlantext }}</span>
        </div>
        <div class="alert-end-icon">
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </section>
      <section *ngIf="confirmFlightPlanSign(leg)" class="alert-box success">
        <div class="alert-group">
          <mat-icon>check_circle</mat-icon>
          <span class="alert-text">{{ flightPlantext }}</span>
        </div>
      </section>
    </ng-container>
  </ng-container>
</ng-container>
