<mat-card class="flight-search-card">
  <div class="flight-search-card-header">
    <span
      *ngIf="passedFlightNum !== '' || passedArrStation !== ''"
      (click)="onBackClick()"
      class="material-icons-outlined back-button"
      >arrow_back</span
    >
    <mat-card-title class="search-flights-title">Search flights</mat-card-title>
    <mat-tab-group
      [(selectedIndex)]="selectedIndex"
      (selectedTabChange)="selectFlightSearch($event)"
    >
      <mat-tab label="By flight number">
        <span class="active-underline"></span>
      </mat-tab>
      <mat-tab label="By dep + arr station ">
        <span *ngIf="selectedIndex === 1" class="active-underline"></span>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="flight-search-card-content">
    <app-flight-number-search-input
      [passedFlightNum]="passedFlightNum"
      [passedDate]="passedDate"
      [passedDepStation]="passedDepStation"
      [passedArrStation]="passedArrStation"
      [flightTabClicked]="flightTabClicked"
    >
    </app-flight-number-search-input>
  </div>
</mat-card>
