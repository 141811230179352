<div class="fos-command-list">
  <div class="content-size">
    <mat-grid-list cols="3" rowHeight="fit" gutterSize="20px">
      <mat-grid-tile [rowspan]="2">
        <div class="left-panel">
          <mat-card>
            <mat-card-title>
              <button (click)="redirectToLandingPage()" mat-icon-button>
                <mat-icon>arrow_backward</mat-icon>
              </button>
              FOS commands
            </mat-card-title>
          </mat-card>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="2" [rowspan]="12">
        <div class="right-panel">
          <ng-container *ngFor="let command of fosCommands; let i = index">
            <mat-card>
              <mat-card-title-group>
                <mat-card-title class="card-title">
                  {{ command.title }}
                </mat-card-title>
                <mat-card-title class="card-title-roboto-mono">
                  {{ command.format }}
                </mat-card-title>
                <ng-container>
                  <button
                    mat-icon-button
                    (click)="toggleDropdown(command)"
                    class="drop-down-button"
                  >
                    <mat-icon>{{
                      !command.isOpen
                        ? 'keyboard_arrow_down'
                        : 'keyboard_arrow_up'
                    }}</mat-icon>
                  </button>
                </ng-container>
              </mat-card-title-group>
              <ng-container *ngIf="command.isOpen">
                <mat-divider class="divider-gap"></mat-divider>
                <mat-card-title-group>
                  <mat-card-subtitle class="card-sub-title"
                    >Example(s)</mat-card-subtitle
                  >
                  <mat-card-title
                    class="card-title-roboto-mono"
                    *ngFor="let eg of command.examples; let j = index"
                  >
                    {{ eg }}
                  </mat-card-title>
                </mat-card-title-group>
              </ng-container>
            </mat-card>
          </ng-container>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
