import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.services';
import { PreflightDocumentModel } from 'src/app/routes/crew/models/pre-flight-document';
import { environment } from 'src/environments/environment';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { User } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getUserState } from 'src/app/shared/store/reducers';
@Injectable({
  providedIn: 'root',
})
export class PreflightDocumentService {
  userObs: Observable<UserState> = this.store.select(getUserState);

  user: User = new User();

  constructor(
    private httpService: HttpService,
    private store: Store<{ user: UserState }>
  ) {
    this.userObs.subscribe({
      next: (userObs) => {
        this.user = userObs.user;
      },
    });
  }

  /**
   * Get preflight documents
   * @returns
   */
  public getPreflightDocuments() {
    const { employeeNumber, businessUnit } = this.user;
    const payload: PreflightDocumentModel = new PreflightDocumentModel(
      'browser',
      employeeNumber ?? 0,
      businessUnit ? businessUnit : 'AAPI'
    );

    return this.httpService.postService(
      `${environment.apiBaseURL}/v1/getPreflightCommands`,
      payload
    );
  }
}
