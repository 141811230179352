import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../../material.module';
import { MatListModule } from '@angular/material/list';
import { SharedModule } from '../../shared/shared.module';
import { UserSessionEffects } from '../../shared/store/effects/user-session.effects';
import { reducers, metaReducers } from '../../shared/store/reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserListComponent } from './components/user-list/user-list.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserModalComponent } from './components/user-modal/user-modal.component';
import { UserDeleteDialogComponent } from './components/user-delete-dialog/user-delete-dialog.component';
import { ConfigListComponent } from './components/config-list/config-list.component';
@NgModule({
  declarations: [
    UserListComponent,
    UserModalComponent,
    UserDeleteDialogComponent,
    ConfigListComponent,
  ],
  imports: [
    MaterialModule,
    MatListModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    EffectsModule.forRoot([UserSessionEffects]),
    AdminRoutingModule,
  ],
  providers: [AuthService],
})
export class AdminModule {}
