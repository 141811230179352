import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/models/Config';
import { HttpService } from 'src/app/shared/services/http.services';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
@Component({
  selector: 'app-config-list',
  templateUrl: './config-list.component.html',
  styleUrls: ['./config-list.component.scss'],
})
export class ConfigListComponent implements OnInit {
  configs: Config[];
  touched = false;
  snackBarConfig: MatSnackBarConfig = {
    duration: 3 * 1000, // seconds
    verticalPosition: 'top',
    horizontalPosition: 'center',
    panelClass: ['primary-snackbar'],
  };

  constructor(
    private _httpService: HttpService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    const url = `${environment.apiBaseURL}/v1/Configuration`;
    this._httpService
      .get<Config[]>(url)
      .pipe(first())
      .subscribe((configs) => {
        this.configs = [
          ...configs.sort((a, b) =>
            a.businessUnit.localeCompare(b.businessUnit)
          ),
        ];
      });
  }

  save(): void {
    const url = `${environment.apiBaseURL}/v1/Configuration`;
    this._httpService
      .post(url, this.configs)
      .pipe(first())
      .subscribe(
        () => {
          this._snackBar.open(
            'Changes saved successfully.',
            'Dismiss',
            this.snackBarConfig
          );
        },
        () => {
          this._snackBar.open(
            'Could not save this change!',
            'Dismiss',
            this.snackBarConfig
          );
        }
      );
  }

  onCheckboxClick(): void {
    this.touched = true;
  }
}
