<div class="container">
  <mat-card>
    <mat-selection-list>
      <mat-list-option
        #list
        *ngFor="let config of configs"
        [(selected)]="config.activeIndicator"
        (click)="onCheckboxClick()"
        color="primary"
      >
        {{ config.businessUnit }} : {{ config.configKey }}
      </mat-list-option>
    </mat-selection-list>
    <div class="actions" align="center">
      <button
        [disabled]="!touched"
        mat-raised-button
        (click)="save()"
        color="primary"
      >
        Save
      </button>
    </div>
  </mat-card>
</div>
