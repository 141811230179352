import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.services';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { Observable } from 'rxjs';
import { getUserState } from 'src/app/shared/store/reducers';
import { User } from 'src/app/models/user';
import { map } from 'rxjs/operators';
import { TripSignInResult } from '../models/trip-signin-result';
interface TripSignInPayload {
  airlineCode: string;
  businessUnit: string;
  employeeLogin: number;
  appSessionId: string;
  siteMinderEmployeeId: number;
  signInDepSta: string;
  standBy: boolean;
  deviceLongtitude: string;
  deviceLatitude: string;
  deviceRadiusError: string;
  deviceType: string;
}

@Injectable({
  providedIn: 'root',
})
export class SabreTransactionsService {
  baseUrl = `${environment.apiBaseURL}`;
  userObs: Observable<UserState> = this.store.select(getUserState);
  user: User = new User();
  latitude: number;
  longitude: number;
  accuracy: number;

  constructor(
    private httpService: HttpService,
    private store: Store<{ user: UserState }>
  ) {
    this.userObs.subscribe({
      next: (userObs) => {
        this.user = userObs.user;
      },
    });
  }

  crewTripSignIn(
    depStation: string,
    standBy: boolean
  ): Observable<TripSignInResult> {
    const url = `${this.baseUrl}/v1/crewTripSignIn`;

    const payload: TripSignInPayload = {
      airlineCode: this.user.airlineCode ?? 'AA',
      businessUnit: this.user.businessUnit ?? '',
      employeeLogin: this.user.employeeNumber,
      appSessionId: 'browser',
      siteMinderEmployeeId: this.user.employeeNumber ?? 0,
      signInDepSta: depStation,
      standBy: standBy,
      deviceLongtitude: this.user.longitude
        ? this.user.longitude.toString()
        : '0',
      deviceLatitude: this.user.latitude ? this.user.latitude.toString() : '0',
      deviceRadiusError: this.user.accuracy
        ? this.user.accuracy.toString()
        : '0',
      deviceType: 'mobile',
    };

    return this.httpService.postWithResponseService(url, payload).pipe(
      map((data) => {
        let tripSignInResponse: any;

        if (data.body && data.body.success) {
          tripSignInResponse = new TripSignInResult(
            data.body.tripSignedIn,
            data.body.tripSignedInMessage
          );
        }
        return tripSignInResponse;
      })
    );
  }
}
