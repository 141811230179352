import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-shared-dialog-component',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class SharedDialogComponent {
  dialogData: any;

  constructor(
    private dialogRef: MatDialogRef<SharedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store,
    private router: Router
  ) {
    if (data) {
      this.dialogData = data;
    }
  }
  tryAgain() {
    this.dialogRef.close({ reason: 'try again' });
  }
}

export function configureSharedDialog(
  dialogTitle: string,
  dialogBody: string,
  showTryAgain = false
): MatDialogConfig {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = false;
  dialogConfig.maxWidth = '360px';

  dialogConfig.data = {
    dialogTitle: dialogTitle,
    dialogBody: dialogBody,
    showTryAgain: showTryAgain,
  };

  return dialogConfig;
}
