import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  postService(url: string, req: any): Observable<any> {
    return this.httpClient
      .post<any>(url, req)
      .pipe(timeout(environment.apiTimeOut));
  }

  postWithResponseService(url: string, body: any): Observable<any> {
    return this.httpClient
      .post<any>(url, body, {
        observe: 'response',
      })
      .pipe(timeout(environment.apiTimeOut));
  }

  getService(url: string): Observable<any> {
    return this.httpClient.get(url).pipe(timeout(environment.apiTimeOut));
  }

  get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url);
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.httpClient
      .post<T>(url, body)
      .pipe(timeout(environment.apiTimeOut));
  }

  downloadFileService(url: string): Observable<any> {
    return this.httpClient
      .get(url, {
        responseType: 'arraybuffer',
      })
      .pipe(timeout(environment.apiTimeOut));
  }
}
