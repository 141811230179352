<!-- [ Flight duty ] -->
<ng-container
  *ngIf="
    flightDuty && flightDuty.flightLegs && flightDuty.flightLegs.length > 0
  "
>
  <mat-card-title-group
    (click)="onClickFlightData()"
    class="sequence-title-group"
  >
    <mat-card-title
      class="today"
      *ngIf="checkIsToday(flightDuty.startDateTime.localTime)"
      >{{
        'Today, ' + (flightDuty.startDateTime.localTime | date: 'MMM d')
      }}</mat-card-title
    >
    <mat-card-title *ngIf="!checkIsToday(flightDuty.startDateTime.localTime)">{{
      flightDuty.startDateTime.localTime | date: 'EE, MMM d'
    }}</mat-card-title>
    <mat-card-actions align="end">
      <mat-icon
        class="material-icons-outlined"
        [ngStyle]="
          flightDuty.isFlightDutyOpen !== undefined &&
          flightDuty.isFlightDutyOpen
            ? { transform: 'rotate(180deg)' }
            : {}
        "
        >arrow_drop_down_circle
      </mat-icon>
    </mat-card-actions>
  </mat-card-title-group>
</ng-container>
<!-- [ Layover ] -->
<ng-container *ngIf="title">
  <mat-card-title-group (click)="onClickLayover()" class="sequence-title-group">
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-actions align="end">
      <mat-card-title
        >{{ layoverData.layOverAirport }}
        {{
          '(' + (layoverData.layoverInMinutes | HoursConverter) + ')'
        }}</mat-card-title
      >
      <mat-icon
        class="material-icons-outlined"
        [ngStyle]="
          flightLeg.isLayoverOpen !== undefined && flightLeg.isLayoverOpen
            ? { transform: 'rotate(180deg)' }
            : {}
        "
        >arrow_drop_down_circle
      </mat-icon>
    </mat-card-actions>
  </mat-card-title-group>
</ng-container>
