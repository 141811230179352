import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as PreflightDataActions from '../actions/pre-flight-documents.actions';
import { PreflightDocumentService } from 'src/app/routes/crew/services/pre-flight-document.service';

@Injectable()
export class PreflightDocumentDataEffects {
  constructor(
    private actions$: Actions,
    private preflightDocumentService: PreflightDocumentService
  ) {}

  preflightDocumentData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreflightDataActions.getPreflightDataAction),
      mergeMap(() =>
        this.preflightDocumentService.getPreflightDocuments().pipe(
          map((data) => {
            if (data.commandList && data.commandList.length > 0) {
              return PreflightDataActions.getPreflightDataSuccessAction({
                preflightData: data.commandList,
              });
            }
            return PreflightDataActions.getPreflightDataSuccessAction({
              preflightData: [],
            });
          }),
          catchError((error) => {
            return of(
              PreflightDataActions.getPreflightDataFailureAction({ error })
            );
          })
        )
      )
    )
  );
}
