import { BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-flight-search',
  templateUrl: './flight-search.component.html',
  styleUrls: ['./flight-search.component.scss'],
})
export class FlightSearchComponent implements OnInit {
  @Input() passedFlightNum = '';
  @Input() passedDate = '';

  @Input() passedDepStation = '';
  @Input() passedArrStation = '';

  selectedIndex = 0;
  constructor(private router: Router) {}

  flightTabClicked: BehaviorSubject<string> = new BehaviorSubject<string>(
    'By flight number'
  );

  ngOnInit(): void {
    if (this.passedDepStation !== '') {
      this.selectedIndex = 1;
      this.flightTabClicked.next('By dep + arr station');
    }
  }

  onBackClick() {
    this.router.navigate(['landing']);
  }

  selectFlightSearch(state: any) {
    this.flightTabClicked.next(state.tab.textLabel);
  }
}
