import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.services';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { Observable } from 'rxjs';
import { getUserState } from 'src/app/shared/store/reducers';
import { User } from 'src/app/models/user';
import { map } from 'rxjs/operators';
import {
  FlightSearchResult,
  InfoIndicators,
  LayOverInfo,
} from '../models/flight-search-result';
import * as moment from 'moment';

interface SequenceDetailsPayload {
  employeeLogin: number;
  siteMinderEmployeeId: number;
  appSessionId: string;
  airlineCode: string;
  businessUnit: string;
  sequenceOriginationDate?: string;
  sequencePosition?: string;
  sequenceNumber?: string;
  sequenceContractMonth?: string;
}

export class SequenceOverviewDetails {
  sequenceNumber: string;
  signInTime: string;
  releaseTime: string;
  noOfDaysInSequence: string;
  numberOfLegsPerDay: string;
  credit: number;
  flightDuty?: FlightSearchResult[];
}

@Injectable({
  providedIn: 'root',
})
export class SequenceDetailsService {
  baseUrl = `${environment.apiBaseURL}`;
  userObs: Observable<UserState> = this.store.select(getUserState);
  user: User = new User();

  constructor(
    private httpService: HttpService,
    private store: Store<{ user: UserState }>
  ) {
    this.userObs.subscribe({
      next: (userObs) => {
        this.user = userObs.user;
      },
    });
  }

  getFlights(): Observable<SequenceOverviewDetails> {
    const url = `${this.baseUrl}/v1/getSequenceDetails`;

    const payload: SequenceDetailsPayload = {
      employeeLogin: this.user.employeeNumber,
      siteMinderEmployeeId: this.user.employeeNumber ?? 0,
      appSessionId: 'browser',
      airlineCode: this.user.airlineCode ?? 'AA',
      businessUnit: this.user.businessUnit ?? '',
      sequenceOriginationDate: this.user.nextActivity?.sequenceOriginationDate,
      sequencePosition: this.user.nextActivity?.sequencePosition,
      sequenceNumber: this.user.nextActivity?.sequenceNumber,
      sequenceContractMonth: this.user.nextActivity?.sequenceContractMonth,
    };

    return this.httpService.postWithResponseService(url, payload).pipe(
      map((data) => {
        let overviewDetails: SequenceOverviewDetails =
          new SequenceOverviewDetails();
        const flightLists: FlightSearchResult[] = [];
        if (data.body && data.body.success) {
          for (const flightList of data.body.sequenceList) {
            overviewDetails = {
              sequenceNumber: flightList.sequenceNumber,
              signInTime: flightList.sequenceStartDateTime.localTime,
              releaseTime: flightList.sequenceEndDateTime.localTime,
              noOfDaysInSequence: flightList.durationInDays,
              numberOfLegsPerDay: flightList.legsPerDutyPeriod,
              credit: flightList.creditThisMonth,
            };

            for (const flights of flightList.flightDutyPeriods) {
              for (const flight of flights.flightLegs) {
                const depDateTime = flight.actual
                  ? moment(flight.actual.departureDateTime.localTime)
                  : moment(flight.scheduled.departureDateTime.localTime);
                const arrDateTime = flight.actual
                  ? moment(flight.actual.arrivalDateTime.localTime)
                  : moment(flight.scheduled.arrivalDateTime.localTime);
                const duration = moment.duration(arrDateTime.diff(depDateTime));
                const flightNum = flight.flightNumber;
                const departureDate = depDateTime
                  .format('MMM DD')
                  .toUpperCase();
                const departureStation = flight.departureStation;
                const departureTime: string = depDateTime.format('HH:mm');
                const enRoute = `${duration.hours()}h ${duration.minutes()}m`;
                const arrivalDate = arrDateTime.format('MMM DD').toUpperCase();
                const arrivalStation = flight.arrivalStation;
                const arrivalTime = arrDateTime.format('HH:mm');
                const airlineCode = flight.airlineCode;
                const infoIndicators = new InfoIndicators(null, null, null);
                const flightDate = flight.actual
                  ? flight.actual.departureDateTime.localTime
                  : flight.scheduled.departureDateTime.localTime;
                const departureGate = flight.departureGate;
                const arrivalGate = flight.arrivalGate;
                const flightOriginationDate = flight.flightOriginationDate;
                const hotelInfo = flight.hotelInfo ? flight.hotelInfo : null;
                const limoInfo = flight.limoInfo ? flight.limoInfo : null;
                const layOverAirport = flights.layOverAirport
                  ? flights.layOverAirport
                  : null;
                const layOverTime = flights.layoverInMinutes
                  ? flights.layoverInMinutes
                  : 0;
                const layOverInfo = new LayOverInfo(
                  hotelInfo,
                  limoInfo,
                  layOverAirport,
                  layOverTime
                );
                const crewMembersWithSameLayover =
                  flight.crewMembersWithSameLayover || null;
                flightLists.push(
                  new FlightSearchResult(
                    flightNum,
                    departureDate,
                    departureStation,
                    departureTime,
                    enRoute,
                    arrivalDate,
                    arrivalStation,
                    arrivalTime,
                    airlineCode,
                    null,
                    flightOriginationDate,
                    infoIndicators,
                    flightDate.slice(0, 10),
                    departureGate,
                    arrivalGate,
                    layOverInfo,
                    crewMembersWithSameLayover
                  )
                );
                overviewDetails.flightDuty = flightLists;
              }
            }
          }
        }
        return overviewDetails;
      })
    );
  }
}
