<section class="preflightdocuments">
  <mat-card>
    <mat-card-title-group
      class="preflight-text"
      (click)="isPreflightDocsOpen = !isPreflightDocsOpen"
    >
      <mat-card-title>Preflight docs</mat-card-title>
      <mat-card-actions align="end">
        <mat-icon
          class="material-icons-outlined"
          [ngStyle]="isPreflightDocsOpen ? { transform: 'rotate(180deg)' } : {}"
          >arrow_drop_down_circle
        </mat-icon>
      </mat-card-actions>
    </mat-card-title-group>
    <div
      class="preflight-list"
      *ngIf="isPreflightDocsOpen && preflightDocuments.length > 0"
    >
      <ng-container *ngFor="let doc of preflightDocuments">
        <mat-card-header (click)="onClickShowPreflightDoc(doc)">
          <div mat-card-avatar>
            <mat-icon class="material-icons-outlined">description</mat-icon>
          </div>
          <mat-card-title class="command-keyword">{{
            doc.commandKeywordDisplay
          }}</mat-card-title>
          <mat-card-title>{{ doc.commandDescription }}</mat-card-title>
          <div class="viewtext-center">
            <a href="javascript:;"> View </a>
          </div>
        </mat-card-header>
      </ng-container>
    </div>
  </mat-card>
</section>
