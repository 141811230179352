<mat-card class="for-commands">
  <mat-card-title class="for-commands-title">FOS commands</mat-card-title>

  <div class="for-commands-chearsheet">
    <span>Cheat sheet</span>
    <button (click)="_gotTo($event)" mat-icon-button>
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</mat-card>
