import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TripSignInService {
  public tripSignIn = new BehaviorSubject<boolean>(false);

  tripSignedIn() {
    this.tripSignIn.next(true);
  }
}
