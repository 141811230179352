<div class="content-size">
  <mat-grid-list cols="3" rowHeight="fit" gutterSize="20px">
    <mat-grid-tile class="overflow-scroll" colspan="2" [rowspan]="6">
      <div class="grid-card">
        <app-sequence></app-sequence>
      </div>
    </mat-grid-tile>
    <mat-grid-tile class="overflow-scroll" [rowspan]="2">
      <div class="grid-card">
        <app-flight-search></app-flight-search>
        <app-fos-commands></app-fos-commands>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
