import { Action, createReducer, on } from '@ngrx/store';
import { User } from 'src/app/models/user';
import * as UserSessionActions from '../actions/user-session.actions';

export interface UserState {
  user: User;
  loggedIn: boolean;
}

export const initializeState = (): UserState => {
  return {
    user: {
      employeeNumber: 0,
      airlineCode: '',
      isAdmin: false,
      businessUnit: '',
      emulateEmployeeNumber: 0,
      typeOfAdmin: '',
      nextActivity: {
        sequenceOriginationDate: '',
        sequenceNumber: '',
        numberOfLegsPerDay: 0,
        totalSequenceDays: 0,
        signInAirport: '',
        signInTime: '',
        activeFlight: {
          flightNumber: '',
          departureTimeLocal: '',
          departureStation: '',
          arrivalTimeLocal: '',
          arrivalStation: '',
          flightOriginationDate: '',
        },
        isSignedIn: false,
      },
      flightDutyPeriods: [],
    },
    loggedIn: false,
  };
};

const initialState = initializeState();

const reducer = createReducer(
  initialState,
  on(UserSessionActions.loginAction, (state: UserState, { userInfo }) => {
    return {
      ...state,
      user: { ...state.user, user: userInfo },
      loggedIn: true,
    };
  }),

  on(
    UserSessionActions.loginSuccessAction,
    (state: UserState, { userInfo }) => {
      return { ...state, user: userInfo, loggedIn: true };
    }
  ),
  on(UserSessionActions.loginFailureAction, (state: UserState) => {
    return { ...state, initialState };
  }),
  on(UserSessionActions.logoutAction, (state: UserState) => {
    return { ...state, initialState };
  })
);

export function UserStateReducer(
  state: UserState | undefined,
  action: Action
): UserState {
  return reducer(state, action);
}

export const getAppState = (state: UserState) => state.user;
export const getAppUserInfo = (state: UserState) => state.user;
