import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/shared/store/reducers';
import * as PreflightDataActions from 'src/app/shared/store/actions/pre-flight-documents.actions';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
@Component({
  selector: 'app-crew-overview',
  templateUrl: './crew-overview.component.html',
  styleUrls: ['./crew-overview.component.scss'],
})
export class CrewOverviewComponent {
  userObs: Observable<UserState> = this.store.select(fromRoot.getUserState);

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit(): void {
    this.userObs.subscribe({
      next: () => {
        this.store.dispatch(PreflightDataActions.getPreflightDataAction());
      },
    });
  }
}
