import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'DateConverter' })
export class DateConverterPipe implements PipeTransform {
  transform(value: string): string {
    const receivedDate = moment(value).format('MM/DD/YYYY');
    const displayDate = moment(value).format('dddd');
    const date = moment().format('MM/DD/YYYY');

    if (receivedDate === date) {
      return 'Today';
    } else {
      return displayDate;
    }
  }
}
