<div class="flight-details-container">
  <div class="details-card-header content-width">
    <span class="left-header">
      <span
        class="material-icons-outlined cursor-pointer vert-align-mid"
        (click)="onBackClick()"
        >arrow_back</span
      >
      <span class="vert-align-mid flight-num-header">
        {{ airlineCode }} {{ flightNum }}
      </span>
    </span>
  </div>
  <div *ngIf="indicatorVisible" class="indicator content-width">
    <div class="inner">
      <span class="material-icons vert-align-mid indicator-icon-padd-right">
        info
      </span>
      <span class="vert-align-mid">
        {{ indicatorText }}
      </span>
    </div>
  </div>
  <app-flight-info-card
    class="content-width flight-card-body"
    [flight]="flight"
    [displayFlightNum]="false"
  ></app-flight-info-card>

  <div class="nose-number-card nose-num">
    <span class="left-content-nose"> Aircraft </span>
    <span class="right-content-nose">
      {{ flight?.noseNum ?? '' }}
    </span>
  </div>
  <!-- [ Pre-flight-documents component ] -->
  <app-pre-flight-documents [flightDetails]="flight"></app-pre-flight-documents>
</div>
