/**
 * Get users element
 * API payload - userManagement
 */
export class AdminUserModel {
  appSessionId: string;
  requestType: string;
  siteMinderEmployeeId: number;
  constructor(
    appSessionId: string,
    requestType: string,
    siteMinderEmployeeId: number
  ) {
    this.appSessionId = appSessionId;
    this.requestType = requestType;
    this.siteMinderEmployeeId = siteMinderEmployeeId;
  }
}

/**
 * For Saving and Editing individual users
 */
export class EditUserModel extends AdminUserModel {
  employeeLogin: number;
  employeeFirstName: string;
  employeeLastName: string;

  constructor(
    appSessionId: string,
    requestType: string,
    siteMinderEmployeeId: number,
    employeeLogin: number,
    employeeFirstName: string,
    employeeLastName: string
  ) {
    super(appSessionId, requestType, siteMinderEmployeeId);
    this.employeeLogin = employeeLogin;
    this.employeeFirstName = employeeFirstName;
    this.employeeLastName = employeeLastName;
  }
}

export class CreateUserModel {
  appSessionId: string;
  requestType: string;
  siteMinderEmployeeId: number;
  employeeLogin: number;
  employeeFirstName: string;
  employeeLastName: string;
  businessUnit: string;
  adminId: number;
}

/**
 * User list element - userManagement
 */
export interface UserListElement {
  firstName: string;
  employeeId: number;
  lastName: string;
  businessUnit: string;
  active: boolean;
  updatedBy: number;
  lastUpdatedDateTime: string;
  adminId: number;
}
