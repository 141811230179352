import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user';
import { Observable, of } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.services';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { getUserState } from 'src/app/shared/store/reducers';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { BasePayload } from 'src/app/routes/crew/services/interfaces/base-payload';
import { map } from 'rxjs/operators';

interface ConfirmFfdPayload extends BasePayload {
  flightNumber: number;
  flightOriginationDate: string;
  originationStation: string;
  arrivalStation: string;
  scheduledDepartureTime: string;
  dupDepartureCode: number;
}

export interface flightPlanStatus {
  fitForDuty?: boolean;
  flightPlanSigned?: boolean;
  returnData: string;
}

@Injectable({
  providedIn: 'root',
})
export class FfdFlightPlanService {
  baseUrl = `${environment.apiBaseURL}`;

  userObs: Observable<UserState> = this.store.select(getUserState);

  user: User;

  flightPlanStatus: flightPlanStatus = {
    fitForDuty: false,
    flightPlanSigned: false,
    returnData: '',
  };

  constructor(
    private httpService: HttpService,
    private store: Store<{ user: UserState }>
  ) {
    this.userObs.subscribe({
      next: (userObs) => {
        this.user = userObs.user;
      },
    });
  }

  confirmFfd(
    flightNumber: number,
    flightOriginationDate: string,
    originationStation: string,
    arrivalStation: string,
    schedultedDepartureTime: string,
    dupDepartureCode: number
  ): Observable<flightPlanStatus> {
    const url = `${this.baseUrl}/v1/signFlightPlanFFD`;
    if (
      this.user.employeeNumber ||
      this.user.airlineCode ||
      this.user.businessUnit
    ) {
      const payload: ConfirmFfdPayload = {
        airlineCode: this.user.airlineCode ?? '',
        businessUnit: this.user.businessUnit ?? '',
        employeeLogin: this.user.employeeNumber,
        appSessionId: 'browser',
        siteMinderEmployeeId: this.user.employeeNumber,
        flightNumber: flightNumber,
        flightOriginationDate: flightOriginationDate,
        originationStation: originationStation,
        arrivalStation: arrivalStation,
        scheduledDepartureTime: schedultedDepartureTime,
        dupDepartureCode: dupDepartureCode,
      };

      return this.httpService.postWithResponseService(url, payload).pipe(
        map((data) => {
          this.flightPlanStatus.fitForDuty = data.body.fitForDuty;
          this.flightPlanStatus.flightPlanSigned = data.body.flightPlanSigned;
          this.flightPlanStatus.returnData = data.body.returnData;
          return this.flightPlanStatus;
        })
      );
    }
    return of(this.flightPlanStatus);
  }
}
