import {
  Component,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader-component',
  templateUrl: './loader-component.component.html',
  styleUrls: ['./loader-component.component.scss'],
})
export class LoaderComponentComponent implements OnDestroy, AfterViewInit {
  loading = false;
  loadingSubscription: Subscription;

  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {
    // Based on the subscription value show or hide loading div.
    this.loadingSubscription = this.loaderService.isLoading.subscribe(
      (value) => {
        this.loading = value;
        this.cdr.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
