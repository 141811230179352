<h2>
  {{
    crudType === 'CREATE'
      ? 'Create User'
      : 'Edit ' +
        createUserModel.employeeFirstName +
        ' ' +
        createUserModel.employeeLastName
  }}
</h2>
<form #userForm="ngForm" (submit)="onSubmitForm()">
  <div class="input-container">
    <mat-form-field>
      <mat-label>Employee Id</mat-label>
      <input
        matInput
        [disabled]="isUpdate"
        name="employeeLogin"
        [(ngModel)]="createUserModel.employeeLogin"
        required
        minlength="3"
        maxlength="8"
        #empId="ngModel"
        (keyup)="onInputChange()"
        (change)="onInputChange()"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input
        name="employeeFirstName"
        required
        maxlength="32"
        matInput
        [(ngModel)]="createUserModel.employeeFirstName"
        #fName="ngModel"
        (keyup)="onInputChange()"
        (change)="onInputChange()"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input
        name="employeeLastName"
        required
        maxlength="32"
        matInput
        [(ngModel)]="createUserModel.employeeLastName"
        #lName="ngModel"
        (keyup)="onInputChange()"
        (change)="onInputChange()"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Business Unit</mat-label>
      <mat-select
        name="businessUnit"
        required
        [(ngModel)]="createUserModel.businessUnit"
        #bUnit="ngModel"
        (keyup)="onInputChange()"
        (selectionChange)="onInputChange()"
      >
        <mat-option *ngFor="let bu of businessUnits" [value]="bu">
          {{ bu }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="button-container">
    <button color="primary" mat-button mat-dialog-close type="button">
      Cancel
    </button>
    <button
      color="primary"
      [disabled]="disableSaveButton"
      mat-button
      type="submit"
    >
      Save
    </button>
  </div>
</form>
