import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.services';
import { SabreCommandsModel } from 'src/app/routes/crew/models/execute-sabre-commands';
import { environment } from 'src/environments/environment';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { User } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getUserState } from 'src/app/shared/store/reducers';
import { getDDMMM, getHHmm, getDay } from 'src/app/shared/util/util';
import { FlightSearchResult } from '../models/flight-search-result';
@Injectable({
  providedIn: 'root',
})
export class ExecuteSabreCommandsService {
  userObs: Observable<UserState> = this.store.select(getUserState);

  user: User = new User();

  constructor(
    private httpService: HttpService,
    private store: Store<{ user: UserState }>
  ) {
    this.userObs.subscribe({
      next: (userObs) => {
        this.user = userObs.user;
      },
    });
  }

  /**
   * Replace the text by value
   * @param commandParameter
   * @param flightDetails
   * @returns
   */
  replaceTextWithCommandParameterValue(
    commandParameter: string,
    flightDetails: any
  ): string {
    const { employeeNumber } = this.user;
    return commandParameter
      .replace('[SPACE]', ' ')
      .replace('[DEPARTUREGATE]', flightDetails.departureGate)
      .replace('[DEPARTUREDATE-DAY]', getDay(flightDetails.departureDate))
      .replace('[FLIGHTNUMBER]', flightDetails.flightNum)
      .replace('[EMPNUMBER]', employeeNumber.toString())
      .replace('[DEPARTUREDATE]', getDDMMM(flightDetails.departureDate))
      .replace('[DEPARTURESTATION]', flightDetails.departureStation)
      .replace('[DEPTIME-ACTUAL]', getHHmm(flightDetails.departureTime))
      .replace('[ARRIVALSTATION]', flightDetails.arrivalStation)
      .replace(
        '[FLTORIGINDATE]',
        getDDMMM(flightDetails.flightOriginationDate)
      );
  }

  /**
   * Creating command parameter
   * @param data
   * @param flightDetails
   * @returns
   */
  createCommandParameter(data: any[], flightDetails: FlightSearchResult) {
    const commandParameters = data.reduce(
      (commandParameters: string, d: any) => {
        return commandParameters.concat(
          this.replaceTextWithCommandParameterValue(
            d.commandParameter,
            flightDetails
          )
        );
      },
      ''
    );
    return commandParameters;
  }

  /**
   * Get sabre commands
   * @param preflightData
   * @param flightDetails
   * @returns
   */
  public getSabreCommands(
    preflightData: any,
    flightDetails: FlightSearchResult
  ) {
    const commandParameters = this.createCommandParameter(
      preflightData.parameterList,
      flightDetails
    );
    const payload: SabreCommandsModel = new SabreCommandsModel(
      this.user,
      preflightData,
      flightDetails,
      commandParameters
    );
    return this.httpService.postService(
      `${environment.apiBaseURL}/v1/executeSabreCommands`,
      payload
    );
  }
}
