import * as fromUserSession from './user-session.reducer';
import * as fromPreflightDocument from './pre-flight-documents.reducer';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import { storageSync } from '@larscom/ngrx-store-storagesync';

export interface State {
  user: fromUserSession.UserState;
  preflightDocuments: fromPreflightDocument.PreflightDocumentState;
}

export const reducers: ActionReducerMap<State> = {
  user: fromUserSession.UserStateReducer,
  preflightDocuments: fromPreflightDocument.PreflightDocumentStateReducer,
};

export function storageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return storageSync<State>({
    features: [{ stateKey: 'user' }],
    storage: window.sessionStorage,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [storageSyncReducer];

/**
 * Get App user data info
 */
export const getAppState = createFeatureSelector<State>('user');
export const getUserState =
  createFeatureSelector<fromUserSession.UserState>('user');
export const getAppUserState = createSelector(
  getUserState,
  fromUserSession.getAppUserInfo
);

/**
 * Get Preflight document data
 */
export const getPreflightState =
  createFeatureSelector<fromPreflightDocument.PreflightDocumentState>(
    'preflightData'
  );
export const getPreflightData = createSelector(
  getPreflightState,
  fromPreflightDocument.getAppPreflightData
);
