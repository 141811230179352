import { FlightSearchResult } from './flight-search-result';

/**
 * POST Method - Get executeSabreCommands
 * Has preflight details
 */
export class SabreCommandRequest {
  commandKeyword: string;
  commandKeywordDisplay: string;
  commandParameters: string;
  constructor(requestData: any, commandParameter: string) {
    this.commandKeyword = requestData.commandKeyword;
    this.commandKeywordDisplay = requestData.commandKeywordDisplay;
    this.commandParameters = commandParameter;
  }
}
/**
 * POST Method - Get executeSabreCommands
 * Has flight details
 */
export class SabreCommandFlightRequest {
  dupDepartureCode: number;
  departureStation: string;
  flightNumber: number;
  flightOriginationDate: string;
  arrivalStation: string;
  actualDepartureTime: number;
  constructor(flightData: FlightSearchResult) {
    this.dupDepartureCode = 0;
    this.departureStation = flightData.departureStation;
    this.flightNumber = parseInt(flightData.flightNum);
    this.flightOriginationDate = flightData.flightOriginationDate ?? '';
    this.arrivalStation = flightData.arrivalStation;
    this.actualDepartureTime = parseInt(
      flightData.departureTime.replace(':', '')
    );
  }
}
/**
 * POST Method - Get executeSabreCommands
 * Has User details, preflight details, flight details
 */
export class SabreCommandsModel {
  appSessionId: string;
  airlineCode: string;
  businessUnit: string;
  employeeLogin: number;
  siteMinderEmployeeId: number;
  commandRequest: SabreCommandRequest;
  flightRequest: SabreCommandFlightRequest;
  constructor(
    user: any,
    preflightData: any,
    flightDetails: FlightSearchResult,
    commandParameter: string
  ) {
    this.appSessionId = user.appSessionId;
    this.airlineCode = user.airlineCode;
    this.businessUnit = user.businessUnit;
    this.employeeLogin = user.emulateEmployeeNumber;
    this.siteMinderEmployeeId = user.employeeNumber;
    this.commandRequest = new SabreCommandRequest(
      preflightData,
      commandParameter
    );
    this.flightRequest = new SabreCommandFlightRequest(flightDetails);
  }
}
