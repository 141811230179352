import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { User } from 'src/app/models/user';
import { jsonClone } from 'src/app/shared/util/util';
import { tripSignInConstants } from 'src/app/routes/crew/crew-constants';
import { NextActivity } from 'src/app/routes/crew/models/sequence-list-results';
import { SharedDialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { configureSharedDialog } from 'src/app/shared/components/dialog/dialog.component';
import { FosResponseDialogComponent } from 'src/app/routes/crew/components/fos-response-dialog/fos-response-dialog.component';
import { configureFosResponseDialog } from 'src/app/routes/crew/components/fos-response-dialog-config';
import { SabreTransactionsService } from 'src/app/routes/crew/services/sabre-transactions.service';
import { TripSignInService } from '../../services/trip-sign-in.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-trip-sign-in',
  templateUrl: './trip-sign-in.component.html',
  styleUrls: ['./trip-sign-in.component.scss'],
})
export class TripSignInComponent implements OnInit {
  nextActivity: NextActivity | any;
  signInText: string;
  userInfo: User;

  constructor(
    private store: Store<{ user: UserState }>,
    private sabreTransactionsService: SabreTransactionsService,
    private fosResponseDialog: MatDialog,
    private sharedDialog: MatDialog,
    private tripSignIn: TripSignInService
  ) {}

  readonly tripSignInConstants = tripSignInConstants;

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(first())
      .subscribe((result) => {
        this.userInfo = result.user;
        this.signInText =
          result.user.crewType === 'P'
            ? tripSignInConstants.signInTripConfirmFFDText
            : tripSignInConstants.signInTripText;
        this.nextActivity = jsonClone(result.user.nextActivity);
      });
  }

  signInForTrip() {
    if (this.nextActivity) {
      const { signInAirport, isStandby } = this.nextActivity;
      if (signInAirport) {
        this.sabreTransactionsService
          .crewTripSignIn(signInAirport, isStandby)
          .subscribe((result) => {
            let dialogConfig = null;
            this.nextActivity.isSignedIn = result.tripSignedIn;
            dialogConfig = configureFosResponseDialog(
              'Trip Sign-In',
              result.tripSignedInMessage
            );
            this.fosResponseDialog.open(
              FosResponseDialogComponent,
              dialogConfig
            );
            if (result.tripSignedIn) {
              this.tripSignIn.tripSignedIn();
              dialogConfig = configureSharedDialog(
                'Success',
                tripSignInConstants.successText
              );
            } else {
              dialogConfig = configureSharedDialog(
                'Failed',
                tripSignInConstants.failureText
              );
            }
            this.sharedDialog.open(SharedDialogComponent, dialogConfig);
          });
      }
    }
  }
}
