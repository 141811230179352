import { Action, createReducer, on } from '@ngrx/store';
import * as PreflightDataActions from '../actions/pre-flight-documents.actions';

export interface PreflightDocumentState {
  preflightData: Array<any>;
}

export const initializeState = (): any => {
  return {
    preflightData: [],
  };
};

const initialState = initializeState();

const reducer = createReducer(
  initialState,
  on(
    PreflightDataActions.getPreflightDataAction,
    (state: PreflightDocumentState) => {
      return {
        ...state,
        preflightData: state.preflightData ? state.preflightData : [],
      };
    }
  ),

  on(
    PreflightDataActions.getPreflightDataSuccessAction,
    (state: PreflightDocumentState, { preflightData }) => {
      return { ...state, preflightData: preflightData };
    }
  ),
  on(
    PreflightDataActions.getPreflightDataFailureAction,
    (state: PreflightDocumentState) => {
      return { ...state, initialState };
    }
  )
);

export function PreflightDocumentStateReducer(
  state: PreflightDocumentState | undefined,
  action: Action
): PreflightDocumentState {
  return reducer(state, action);
}
export const getAppPreflightData = (state: PreflightDocumentState) =>
  state.preflightData;
