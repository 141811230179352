<div class="page-header-container">
  <div class="page-header">
    <img
      (click)="redirectToLanding()"
      class="aa-header-img"
      src="../../assets/aa_CCI_header.png"
    />
    <span class="header-spacer"></span>
    <button mat-button [matMenuTriggerFor]="profile">
      <mat-icon class="material-icons-outlined icon-color-white"
        >account_circle</mat-icon
      >
    </button>
    <mat-menu #profile="matMenu" [overlapTrigger]="false">
      <form
        *ngIf="isAdmin"
        #emulateForm="ngForm"
        (keydown.tab)="$event.stopPropagation()"
      >
        <mat-toolbar>
          <mat-toolbar-row (click)="$event.stopPropagation(); (false)">
            <mat-form-field>
              <input
                mat-menu-item
                matInput
                type="text"
                placeholder="Emulate ID"
                name="emulateId"
                [(ngModel)]="emulateModel.emulateId"
              />
              <button
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear Emulate Id"
                (click)="emulateModel.emulateId = ''"
                [style.visibility]="
                  !emulateModel.emulateId ? 'hidden' : 'visible'
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </mat-toolbar-row>
          <mat-toolbar-row (click)="$event.stopPropagation(); (false)">
            <mat-form-field>
              <input
                matInput
                type="text"
                placeholder="Emulate BU"
                name="emulateBU"
                [(ngModel)]="emulateModel.emulateBU"
              />
              <button
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear password"
                tabindex="-1"
                (click)="emulateModel.emulateBU = ''"
                [style.visibility]="
                  !emulateModel.emulateBU ? 'hidden' : 'visible'
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </mat-toolbar-row>
          <mat-toolbar-row>
            <button
              mat-raised-buton
              class="submit"
              (click)="emulate(emulateForm)"
            >
              Submit
            </button>
          </mat-toolbar-row>
        </mat-toolbar>
      </form>
      <mat-toolbar>
        <mat-toolbar-row *ngIf="isAdmin">
          <button class="mat-menu-item" (click)="redirectToAdminUserList()">
            <mat-icon>list</mat-icon>User List
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row *ngIf="isAdmin">
          <button class="mat-menu-item" (click)="redirectToConfig()">
            <mat-icon>settings</mat-icon>Config
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row>
          <button class="mat-menu-item" (click)="logout()">
            <mat-icon>logout</mat-icon>Logout
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
    </mat-menu>
  </div>
</div>
