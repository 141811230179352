<div>
  <div [hidden]="displayFlight !== 'By flight number'">
    <mat-form-field
      floatLabel="never"
      class="flight-number-input-form-field"
      appearance="fill"
    >
      <input
        required
        [pattern]="util.flightNumValidation"
        #flightNumInputEl="ngModel"
        (focusout)="removeUnderline()"
        (focus)="addUnderline()"
        [(ngModel)]="flightNum"
        type="text"
        matInput
        placeholder="Flight Number"
      />
      <mat-icon
        (mousedown)="clearIconClick()"
        style="display: none"
        matSuffix
        class="clear-icon icon-color-white form-control"
        >cancel</mat-icon
      >
    </mat-form-field>
  </div>
  <form [formGroup]="searchForm">
    <mat-form-field
      *ngIf="displayFlight !== 'By flight number'"
      floatLabel="never"
      class="flight-area-input-form-field"
      appearance="fill"
    >
      <input
        formControlName="departureStation"
        placeholder="DFW"
        matInput
        [(ngModel)]="departureStation"
        type="text"
      />
      <mat-icon
        (mousedown)="clearIconClickStation('departureStation')"
        style="display: none"
        matSuffix
        class="clear-icon icon-color-white"
        >cancel</mat-icon
      >
    </mat-form-field>

    <mat-form-field
      floatLabel="never"
      *ngIf="displayFlight !== 'By flight number'"
      class="flight-area-input-form-field"
      appearance="fill"
    >
      <input
        formControlName="arrivalStation"
        placeholder="SEA"
        matInput
        [(ngModel)]="arrivalStation"
        type="text"
      />
      <mat-icon
        (mousedown)="clearIconClickStation('arrivalStation')"
        style="display: none"
        matSuffix
        class="clear-icon icon-color-white"
        >cancel</mat-icon
      >
    </mat-form-field>
  </form>
  <mat-form-field
    floatLabel="never"
    appearance="fill"
    class="flight-number-input-form-field"
  >
    <input
      readonly
      type="text"
      [(ngModel)]="originationDate"
      matInput
      [matDatepicker]="picker"
    />
    <mat-datepicker-toggle matSuffix [for]="picker">
      <img
        class="flight-search-calendar-icon"
        matDatepickerToggleIcon
        src="assets/calendar.png"
      />
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <button
    (click)="
      displayFlight === 'By flight number'
        ? onSearchClick('flightNum')
        : onSearchClick('station')
    "
    id="flightNumSearchButton"
    [disabled]="
      (displayFlight === 'By flight number' && flightNumInputEl.invalid) ||
      (displayFlight !== 'By flight number' && searchForm.invalid)
    "
    mat-button
    class="flight-number-search-button"
  >
    Search
  </button>
</div>
