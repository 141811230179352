import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExecuteSabreCommandsService } from '../../services/execute-sabre-commands.service';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import jsPDF from 'jspdf';

pdfDefaultOptions.assetsFolder = 'bleeding-edge';

@Component({
  selector: 'app-fos-response-dialog-component',
  templateUrl: './fos-response-dialog.component.html',
  styleUrls: ['./fos-response-dialog.component.scss'],
})
export class FosResponseDialogComponent {
  dialogData: any;

  pdfData: string;

  isPdf = false;

  executeSabreCommandsService: ExecuteSabreCommandsService;

  constructor(
    private dialogRef: MatDialogRef<FosResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private executeSabreCommandService: ExecuteSabreCommandsService
  ) {
    if (data) {
      this.dialogData = data;
    }

    if (data.pdfData !== null) {
      this.isPdf = true;
      this.pdfData = `data:application/pdf;base64,${data.pdfData}`;
    }
  }

  downloadPDF() {
    const doc = new jsPDF();

    const bodyContent = doc.splitTextToSize(this.dialogData.dialogBody, 250);
    doc.setFontSize(14);
    doc.setFont('Courier');
    doc.text(this.dialogData.dialogTitle, 15, 15);
    doc.setFontSize(12);
    let yAxis = 25;
    for (let i = 0; i < bodyContent.length; i++) {
      //check to see if the content of the pdf is bigger than the pdf height
      if (yAxis > 280) {
        yAxis = 20;
        //add a new page if the content is bigger than than the pdf page
        doc.addPage();
      }
      doc.text(bodyContent[i], 15, yAxis);
      yAxis = yAxis + 7;
    }

    doc.save(this.dialogData.dialogTitle);
  }
}
