import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateUserModel } from '../../models/user';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user-delete-dialog.component.scss'],
})
export class UserDeleteDialogComponent {
  user = new CreateUserModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserDeleteDialogComponent>,
    private userService: UserService,
    private _snackBar: MatSnackBar
  ) {
    this.user.employeeFirstName = data.firstName;
    this.user.employeeLastName = data.lastName;
    this.user.employeeLogin = data.employeeId;
    this.user.businessUnit = data.businessUnit;
    this.user.adminId = data.adminId;
  }

  onDeleteClick() {
    this.userService.crudUser(this.user, 'DELETE').subscribe((response) => {
      if (response) {
        this.dialogRef.close('success');
      } else {
        this._snackBar.open('something went wrong', undefined, {
          duration: 3000,
        });
      }
    });
  }
}
