import { MatDialogConfig } from '@angular/material/dialog';

export function configureFosResponseDialog(
  dialogTitle: string,
  dialogBody: string,
  pdfData: any | null = null
): MatDialogConfig {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = false;

  dialogConfig.data = {
    dialogTitle: dialogTitle,
    dialogBody: dialogBody,
    pdfData: pdfData,
  };

  dialogConfig.maxWidth = '100vw';
  dialogConfig.minHeight = '100vh';

  return dialogConfig;
}
