import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FlightSearchService } from '../../../services/flight-search.service';
import { FlightSearchResult } from '../../../models/flight-search-result';

@Component({
  selector: 'app-flight-info-card',
  templateUrl: './flight-info-card.component.html',
  styleUrls: ['./flight-info-card.component.scss'],
})
export class FlightInfoCardComponent {
  constructor(
    private router: Router,
    flightSearchService: FlightSearchService
  ) {
    this.flightSearchService = flightSearchService;
  }

  @Input() flight: FlightSearchResult;
  @Input() displayFlightNum = true;

  isOpen = false;
  isCrewOpen = false;
  positionIsOpen = '000000';

  toggleLayover(isOpen: boolean) {
    this.isOpen = !isOpen;
  }

  toggleCrew(isCrewOpen: boolean) {
    this.isCrewOpen = !isCrewOpen;
  }

  flightSearchService: FlightSearchService;

  redirectToFlightDetails() {
    const flightNum = this.flight.flightNum;
    const airlineCode = this.flight.airlineCode;

    this.router.navigate([
      `flightDetails/${flightNum}/${airlineCode}/${this.flight.departureStation}/${this.flight.arrivalStation}/${this.flight.flightOriginationDate}`,
    ]);
  }
}
