import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-flight-search-page',
  templateUrl: './flight-search-page.component.html',
  styleUrls: ['./flight-search-page.component.scss'],
})
export class FlightSearchPageComponent implements OnInit {
  flightNum = '';
  date = '';

  depStation = '';
  arrStation = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['depStation'] === undefined) {
        this.flightNum = params['num'];
        this.date = params['date'];
      } else {
        this.depStation = params['depStation'];
        this.arrStation = params['arrStation'];
        this.date = params['date'];
      }
    });
  }
}
