import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/shared/store/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExecuteSabreCommandsService } from 'src/app/routes/crew/services/execute-sabre-commands.service';
import { configureFosResponseDialog } from 'src/app/routes/crew/components/fos-response-dialog-config';
import { FosResponseDialogComponent } from 'src/app/routes/crew/components/fos-response-dialog/fos-response-dialog.component';
import { FlightSearchResult } from '../../models/flight-search-result';
@Component({
  selector: 'app-pre-flight-documents',
  templateUrl: './pre-flight-documents.component.html',
  styleUrls: ['./pre-flight-documents.component.scss'],
})
export class PreFlightDocumentsComponent implements OnInit {
  @Input() flightDetails: FlightSearchResult;

  isPreflightDocsOpen = false;

  preflightDocuments: Array<any> = [];

  snackBarErrorMessage = 'Error message';

  constructor(
    private store: Store<fromRoot.State>,
    public dialog: MatDialog,
    private executeSabreCommandsService: ExecuteSabreCommandsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.store.select('preflightDocuments').subscribe((result) => {
      if (result.preflightData && result.preflightData.length > 0) {
        this.preflightDocuments = result.preflightData;
      }
    });
  }

  /**
   * Show snackbar error
   */
  showSnackBar() {
    this.snackBar.open(this.snackBarErrorMessage, undefined, {
      duration: 3000,
    });
  }

  /**
   * On click show preflight document data
   * @param preflightData
   */
  onClickShowPreflightDoc(preflightData: any) {
    this.executeSabreCommandsService
      .getSabreCommands(preflightData, this.flightDetails)
      .subscribe(
        (data: any) => {
          if (data && data.commandResponse.pdfOutput) {
            const dialogConfig = configureFosResponseDialog(
              'Flight Plan PDF',
              '',
              data.commandResponse.pdfOutput
            );
            this.dialog.open(FosResponseDialogComponent, dialogConfig);
          } else if (data && data.commandResponse) {
            const dialogConfig = configureFosResponseDialog(
              preflightData.commandDescription,
              data.commandResponse.returnData
            );
            this.dialog.open(FosResponseDialogComponent, dialogConfig);
          } else {
            this.showSnackBar();
          }
        },
        () => {
          this.showSnackBar();
        }
      );
  }
}
