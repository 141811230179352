import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/shared/store/reducers';
import * as UserSessionActions from 'src/app/shared/store/actions/user-session.actions';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/models/user';
@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent implements OnInit {
  isAdmin = false;
  public emulateModel: any = { emulateId: '', emulateBU: '' };
  constructor(private router: Router, private store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.store.select('user').subscribe((result) => {
      if (result && result.user) {
        this.isAdmin = result.user.isAdmin || false;
      }
    });
  }

  logout(): void {
    sessionStorage.clear();
    this.store.dispatch(UserSessionActions.logoutAction());
    this.router.navigate(['/logout']);
  }

  emulate(form: NgForm): void {
    if (form.valid) {
      let userInfo = new User();
      userInfo = {
        airlineCode: this.emulateModel.emulateBU.slice(0, 2).toUpperCase(),
        employeeNumber: Number.parseInt(this.emulateModel.emulateId),
        businessUnit: this.emulateModel.emulateBU.toUpperCase(),
      };

      this.store.dispatch(UserSessionActions.loginAction({ userInfo }));
    }
  }

  redirectToAdminUserList() {
    this.router.navigateByUrl('/users');
  }

  redirectToConfig() {
    this.router.navigateByUrl('/config');
  }

  redirectToLanding() {
    this.router.navigateByUrl('/landing');
  }
}
