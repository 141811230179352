import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-fos-commands',
  templateUrl: './fos-commands.component.html',
  styleUrls: ['./fos-commands.component.scss'],
})
export class FosCommandsComponent {
  constructor(private router: Router) {}

  /**
   * Redirect to another path
   * @param e - event
   */
  public _gotTo(e: Event) {
    if (e) {
      this.router.navigateByUrl('/fos-command');
    }
  }
}
