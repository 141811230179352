import { NextActivity } from '../routes/crew/models/sequence-list-results';
import { FlightLeg } from './flightLeg';

export class UserInfo {
  firstName: string;
  lastName: string;
  employeeNumber: number;
  email: string;
  airlineCode: string;
  constructor(id: any) {
    this.email = id.email;
    if (id.uid) {
      this.firstName = id.first_name;
      this.lastName = id.last_name;
      this.employeeNumber = Number.parseInt(String(id.uid), 10);
      this.airlineCode = id.amrcompanycode
        ? id.amrcompanycode.substring(0, 2)
        : 'MQ';
    } else {
      this.firstName = id.firstName;
      this.lastName = id.lastName;
      this.employeeNumber = id.employeeNumber;
      this.airlineCode = id.airlineCode;
    }
  }
}

export class User {
  public firstName?: string;

  public lastName?: string;

  public employeeNumber: number;

  public emulateEmployeeNumber?: number;

  public businessUnit?: string;

  public airlineCode?: string;

  public nextActivity?: NextActivity;

  public isAdmin?: boolean;

  public cciliteEnabled?: boolean;

  public typeOfAdmin?: string;

  public email?: string;

  public otherBU?: string;

  public readAndSignAdminUser?: boolean;

  public latitude?: number;

  public longitude?: number;

  public accuracy?: number;

  public flightLegs?: FlightLeg[];

  public crewType?: string;

  public flightDutyPeriods?: [];

  constructor() {
    // empty constructor
  }
}
