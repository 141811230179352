<div class="content-size">
  <mat-grid-list cols="3" rowHeight="fit" class="grid-height" gutterSize="20px">
    <mat-grid-tile>
      <div class="grid-card">
        <app-flight-search
          [passedFlightNum]="flightNum"
          [passedDate]="date"
          [passedDepStation]="depStation"
          [passedArrStation]="arrStation"
        >
        </app-flight-search>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" class="grid-align-center overflow-visible">
      <div class="grid-card">
        <app-flight-search-results
          [flightNum]="flightNum"
          [date]="date"
          [depStation]="depStation"
          [arrStation]="arrStation"
        ></app-flight-search-results>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
