<div class="mat-dialog-card">
  <h1 mat-dialog-title>{{ dialogData.dialogTitle }}</h1>
  <div mat-dialog-content>{{ dialogData.dialogBody }}</div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close *ngIf="!dialogData.showTryAgain">
      Ok
    </button>
    <button
      class="cancel"
      mat-button
      mat-dialog-close
      *ngIf="dialogData.showTryAgain"
    >
      Cancel
    </button>
    <button mat-button (click)="tryAgain()" *ngIf="dialogData.showTryAgain">
      Try again
    </button>
  </div>
</div>
