export const tripSignInConstants = {
  successText:
    'You are signed in for this trip and confirmed fit for duty for leg 1.',
  failureText: 'Trip Sign-In failed.',
  signInTripConfirmFFDText: 'Sign in for trip + confirm FFD',
  signInTripText: 'Sign in for trip',
  SignedInTripText: "You're signed in",
};

export const IndicatorConstants = {
  rightStartText: 'Right start',
  postFlightInspectionText: 'Post flight inspection',
};

export const fitForDutyConstants = {
  successText: "You're confirmed FFD",
  failureText: 'You are NOT confirmed fit for duty.',
  confirmedText: "You're confirmed FFD",
  confirmationText: 'Confirm FFD',
};

export const cciNotEnabledConstants = {
  message: 'Please use the CCI App or click Ok to go to crewcheckin.aa.com',
};

export const flightPlanConstants = {
  legOneFlightPlanNotSigned: 'Sign Flight Plan',
  legTwoFlightPlanNotSigned: 'Sign flight plan + confirm FFD',
  flightPlanSigned: 'Flight plan signed',
};

export const dialogText = {
  successText: 'Success',
  failureText: 'Something went wrong',
};
