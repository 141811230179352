import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PingAuthGuard } from '@techops-ui/ping-authentication';
import { FlightDetailsPageComponent } from './components/flight-details-page/flight-details-page.component';
import { FlightSearchPageComponent } from './components/flight-search-page/flight-search-page.component';
import { SequenceOverviewComponent } from './components/sequence/sequence-overview/sequence-overview.component';
import { FosCommandListComponent } from './components/fos-command-list/fos-command-list.component';

const routes: Routes = [
  {
    path: 'fos-command',
    component: FosCommandListComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'flightSearch/flightNum/:num/:date',
    component: FlightSearchPageComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'flightSearch/stations/:depStation/:arrStation/:date',
    component: FlightSearchPageComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'flightDetails/:flightNum/:airlineCode/:depStation/:arrStation/:flightStartTime',
    component: FlightDetailsPageComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'sequence-overview',
    component: SequenceOverviewComponent,
    canActivate: [PingAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrewRoutingModule {}
