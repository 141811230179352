import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../../material.module';
import { FlightSearchComponent } from './components/flight-search/flight-search.component';
import { FlightNumberSearchInputComponent } from './components/flight-search/flight-number-search-input/flight-number-search-input.component';
import { SharedModule } from '../../shared/shared.module';
import { UserSessionEffects } from '../../shared/store/effects/user-session.effects';
import { reducers, metaReducers } from '../../shared/store/reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CrewRoutingModule } from './crew-routing.module';
import { FlightSearchResultsComponent } from './components/flight-search/flight-search-results/flight-search-results.component';
import { SequenceComponent } from './components/sequence/sequence.component';
import { FosCommandsComponent } from './components/fos-commands/fos-commands.component';
import { FlightSearchPageComponent } from './components/flight-search-page/flight-search-page.component';
import { CrewHeaderComponent } from './components/crew-header/crew-header.component';
import { CrewOverviewComponent } from './components/crew-overview/crew-overview.component';
import { FlightInfoCardComponent } from './components/flight-search/flight-info-card/flight-info-card.component';
import { FlightDetailsPageComponent } from './components/flight-details-page/flight-details-page.component';
import { FosResponseDialogComponent } from './components/fos-response-dialog/fos-response-dialog.component';
import { SequenceOverviewComponent } from './components/sequence/sequence-overview/sequence-overview.component';
import { SequenceCardComponent } from './components/sequence/sequence-overview/sequence-card/sequence-card.component';
import { HoursConverterPipe } from 'src/app/shared/pipes/hours-convert.pipe';
import { DateConverterPipe } from 'src/app/shared/pipes/fliter-dates.pipes';
import { FosCommandListComponent } from './components/fos-command-list/fos-command-list.component';
import { PreFlightDocumentsComponent } from 'src/app/routes/crew/components/pre-flight-documents/pre-flight-documents.component';
import { FlightInfoComponent } from './components/sequence/flight-info/flight-info.component';
import { FlightInfoOverviewComponent } from './components/sequence/flight-info-overview/flight-info-overview.component';
import { TripSignInComponent } from '../../shared/components/trip-sign-in/trip-sign-in.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [
    FlightSearchComponent,
    FlightNumberSearchInputComponent,
    FlightSearchResultsComponent,
    SequenceComponent,
    FosCommandsComponent,
    FlightSearchPageComponent,
    CrewHeaderComponent,
    CrewOverviewComponent,
    FlightInfoCardComponent,
    FlightDetailsPageComponent,
    FosResponseDialogComponent,
    SequenceOverviewComponent,
    SequenceCardComponent,
    HoursConverterPipe,
    DateConverterPipe,
    FosCommandListComponent,
    PreFlightDocumentsComponent,
    FlightInfoComponent,
    FlightInfoOverviewComponent,
    TripSignInComponent,
  ],
  imports: [
    MaterialModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    EffectsModule.forRoot([UserSessionEffects]),
    CrewRoutingModule,
    NgxExtendedPdfViewerModule,
  ],
  providers: [],
})
export class CrewModule {}
