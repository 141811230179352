import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';
import { Util, dateToStringYYYYMMDD } from 'src/app/shared/util/util';
import { Router } from '@angular/router';
import { FlightSearchService } from '../../../services/flight-search.service';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY ',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-flight-number-search-input',
  templateUrl: './flight-number-search-input.component.html',
  styleUrls: ['./flight-number-search-input.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FlightNumberSearchInputComponent implements OnInit {
  @Input() passedFlightNum = '';
  @Input() passedDate = '';
  @Input() passedDepStation = '';
  @Input() passedArrStation = '';
  @Input() flightTabClicked!: BehaviorSubject<any>;

  searchLink = '';
  util = new Util();
  flightNum = '';
  arrivalStation = '';
  departureStation = '';
  originationDate: Date = new Date();
  isButtonDisabled = true;
  searchForm!: FormGroup;
  displayFlight = 'By flight number';

  ngOnInit() {
    this.searchForm = this.fb.group({
      departureStation: [
        null,
        [
          Validators.required,
          Validators.pattern(this.util.flightStationValidation),
        ],
      ],
      arrivalStation: [
        null,
        [
          Validators.required,
          Validators.pattern(this.util.flightStationValidation),
        ],
      ],
      originationDate: [this.originationDate],
    });

    this.flightTabClicked.subscribe((data) => {
      this.displayFlight = data;
    });

    if (this.passedFlightNum !== '') {
      this.flightNum = this.passedFlightNum;
      this.originationDate = moment(this.passedDate, 'YYYY-MM-DD').toDate();
    } else if (this.passedArrStation !== '') {
      this.arrivalStation = this.passedArrStation;
      this.departureStation = this.passedDepStation;
      this.originationDate = moment(this.passedDate, 'YYYY-MM-DD').toDate();
    }
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private flightSearchService: FlightSearchService
  ) {}

  onSearchClick(searchType: string) {
    const origDate = dateToStringYYYYMMDD(this.originationDate);
    const flightNum = searchType === 'flightNum' ? this.flightNum : null;
    const depStation =
      searchType !== 'flightNum' ? this.departureStation : null;
    const arrStation = searchType !== 'flightNum' ? this.arrivalStation : null;
    this.flightSearchService
      .getFlights(origDate, flightNum, depStation, arrStation)
      .subscribe((data) => {
        if (data.length < 1) {
          alert('No Flight Returned for search results');
        } else {
          if (searchType !== 'flightNum') {
            this.router.navigate([
              'flightSearch/stations',
              depStation,
              arrStation,
              origDate,
            ]);
          } else {
            this.router.navigate([
              'flightSearch/flightNum',
              flightNum,
              origDate,
            ]);
          }
        }
      });
  }

  addUnderline() {
    const underline = document.getElementsByClassName(
      'mat-form-field-underline'
    )[0];
    const flightNumContainer = document.getElementsByClassName(
      'mat-form-field-flex'
    )[0];
    flightNumContainer.setAttribute('style', 'border-radius: 8px 8px 2px 2px');
    underline.setAttribute('style', 'display: block');

    const icon = document.getElementsByClassName('clear-icon')[0];
    icon.setAttribute('style', 'display: block');
  }

  removeUnderline() {
    const underline = document.getElementsByClassName(
      'mat-form-field-underline'
    )[0];
    const flightNumContainer = document.getElementsByClassName(
      'mat-form-field-flex'
    )[0];
    flightNumContainer.setAttribute('style', 'border-radius: 8px');
    underline.setAttribute('style', 'display: none');

    const icon = document.getElementsByClassName('clear-icon')[0];
    icon.setAttribute('style', 'display: none');
  }

  clearIconClick() {
    this.flightNum = '';
    this.isButtonDisabled = true;
  }

  // Clears the field when the user clicks on the cross icon inside the input box
  clearIconClickStation(station: string) {
    if (station === 'arrivalStation') {
      this.arrivalStation = '';
    } else {
      this.departureStation = '';
    }
  }
}
