import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import * as UserSessionActions from '../actions/user-session.actions';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedDialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { configureSharedDialog } from 'src/app/shared/components/dialog/dialog.component';
import { cciNotEnabledConstants } from '../../../routes/crew/crew-constants';

export interface activeFlights {
  flightNumber: number;
  flightDepartureTime: string;
  flightDepartureAirport: string;
  flightArrivalTime: string;
  flightArrivalAirport: string;
}

@Injectable()
export class UserSessionEffects {
  latitude: number;
  longitude: number;
  accuracy: number;
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSessionActions.loginAction),
      mergeMap((action) =>
        this.authService.getLoginDetails(action.userInfo).pipe(
          map((data) => {
            let userInfo = new User();
            if (data && data.success) {
              this.getLocation();
              userInfo = this.setUserInfo(action, data);
              if (
                userInfo.isAdmin === true ||
                userInfo.cciliteEnabled === true
              ) {
                if (
                  data.dashBoardResponse &&
                  data.dashBoardResponse.nextActivity
                ) {
                  userInfo = this.setUserInfoNextActivity(userInfo, data);
                }
                return UserSessionActions.loginSuccessAction({ userInfo });
              } else {
                let dialogConfig = new MatDialogConfig<any>();
                dialogConfig = configureSharedDialog(
                  'CCI Is Online',
                  cciNotEnabledConstants.message,
                  false
                );
                dialogConfig.disableClose = true;
                const logout = this.sharedDialog.open(
                  SharedDialogComponent,
                  dialogConfig
                );
                logout.afterClosed().subscribe(() => {
                  sessionStorage.clear();
                  window.location.href =
                    'https://crewcheckin.aa.com/CrewCheckinWebApp/index.html#/tabs/tab-0/home';
                });
              }
            }
            this.router.navigate(['/sessions/403']);
            return UserSessionActions.loginFailureAction({});
          }),
          catchError((error) => {
            if (
              error.error_description &&
              error.error_description.includes('Access token expired')
            ) {
              sessionStorage.clear();
              this.router.navigate(['/logout']);
              return of(UserSessionActions.logoutAction());
            }
            this.router.navigate(['/sessions/500']);
            return of(UserSessionActions.loginFailureAction({ error }));
          })
        )
      )
    )
  );

  /**
   * set user information next activity
   * @param userInfo
   * @param data
   * @returns
   */
  setUserInfoNextActivity(userInfo: any, data: any) {
    userInfo.crewType = data.dashBoardResponse.crewType; //value of P or F (for pilot and flight attendant respectively),
    userInfo.nextActivity = {
      sequenceOriginationDate:
        data.dashBoardResponse.nextActivity.sequenceOriginationDate,
      sequenceNumber: data.dashBoardResponse.nextActivity.sequenceNumber,
      totalSequenceDays: data.dashBoardResponse.nextActivity.totalSequenceDays,
      numberOfLegsPerDay:
        data.dashBoardResponse.nextActivity.numberOfLegsPerDay,
      signInAirport: data.dashBoardResponse.nextActivity.signInAirport,
      signInTime: data.dashBoardResponse.nextActivity.signInTime,
      isSignedIn: data.dashBoardResponse.nextActivity.signedIn,
      isSignInEligible: data.dashBoardResponse.nextActivity.isSignInEligible,
      isStandby: data.dashBoardResponse.nextActivity.isStandby,
      sequencePosition: data.dashBoardResponse.nextActivity.sequencePosition,
      sequenceContractMonth:
        data.dashBoardResponse.nextActivity.sequenceContractMonth,
    };
    return userInfo;
  }

  /**
   * set User Information
   * @param action
   * @param data
   * @returns
   */
  setUserInfo(action: any, data: any) {
    let tempFlightDutyPeriods = [];
    if (
      data &&
      data.dashBoardResponse &&
      data.dashBoardResponse.activeOrNextSequences &&
      data.dashBoardResponse.activeOrNextSequences.length > 0
    ) {
      tempFlightDutyPeriods =
        data.dashBoardResponse.activeOrNextSequences[0].flightDutyPeriods ?? [];
    }
    return {
      airlineCode: data.businessUnit.substring(0, 2),
      employeeNumber: action.userInfo.employeeNumber,
      businessUnit: data.businessUnit,
      firstName: data.firstName ? data.firstName : action.userInfo.firstName,
      lastName: data.lastName ? data.lastName : action.userInfo.lastName,
      email: action.userInfo.email,
      latitude: this.latitude,
      longitude: this.longitude,
      accuracy: this.accuracy,
      isAdmin: data.admin === null ? false : data.admin,
      cciliteEnabled: data.cciliteEnabled,
      nextActivity: {},
      flightDutyPeriods: tempFlightDutyPeriods,
    };
  }

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSessionActions.loginSuccessAction),
        tap(() =>
          this.router.navigate(
            [
              this.router.url &&
              !this.router.url.includes('auth') &&
              this.router.url !== '/'
                ? this.router.url
                : '/landing',
            ],
            { replaceUrl: true }
          )
        )
      ),
    { dispatch: false }
  );

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          if (position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.accuracy = position.coords.accuracy;
          }
        },
        (error: GeolocationPositionError) => {
          alert('Error reading your Geo Location ' + error.message);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private sharedDialog: MatDialog
  ) {}
}
