<section class="sequence-container">
  <ng-container
    *ngIf="
      nextActivity &&
      (nextActivity.sequenceNumber === undefined ||
        nextActivity.sequenceNumber === null ||
        flightDutyPeriods.length === 0)
    "
  >
    <div class="no-sequence">
      <mat-card>
        <mat-card-title>Upcoming </mat-card-title>
        <mat-card-content> No Events </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngIf="nextActivity && nextActivity.sequenceNumber">
    <div class="has-sequence">
      <!-- [ Flight next activity ]-->
      <mat-card>
        <mat-card-title
          >{{ nextActivity.sequenceOriginationDate | date: 'EE, MMM d' }}
          {{
            sequenceEndDate ? ' - ' + (sequenceEndDate | date: 'EE, MMM d') : ''
          }}
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-title-group (click)="navigateToSequenceOverview()">
          <mat-card-title>SEQ {{ nextActivity.sequenceNumber }}</mat-card-title>
          <mat-card-actions align="end">
            <mat-card-title>
              {{ nextActivity.totalSequenceDays }}({{
                nextActivity.numberOfLegsPerDay
              }})
            </mat-card-title>
          </mat-card-actions>
        </mat-card-title-group>
        <mat-divider></mat-divider>
        <div>
          <app-trip-sign-in></app-trip-sign-in>
        </div>
      </mat-card>
      <!-- [ Flight Legs Logic ]-->
      <ng-container *ngIf="flightDutyPeriods.length > 0">
        <ng-container
          *ngFor="let flightDuty of flightDutyPeriods; let i = index"
        >
          <mat-card
            *ngIf="flightDuty.flightLegs && flightDuty.flightLegs.length > 0"
          >
            <app-flight-info
              [flightDuty]="flightDuty"
              (onClickFlightDutyData)="
                toggleFlightDutyDropdown($event, flightDuty)
              "
            ></app-flight-info>
            <app-flight-info-overview
              *ngIf="flightDuty.isFlightDutyOpen"
              [flightDuty]="flightDuty"
              [nextActivity]="nextActivity"
              (onClickFlightDetails)="goToFlightDetailsPage($event)"
              (onExecuteFFDcommand)="executeFFDcommand($event)"
            ></app-flight-info-overview>
          </mat-card>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</section>
