import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PingAuthGuard } from '@techops-ui/ping-authentication';
import { CrewOverviewComponent } from './routes/crew/components/crew-overview/crew-overview.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'landing',
    component: CrewOverviewComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'sessions',
    loadChildren: () =>
      import('./routes/sessions/sessions.module').then((m) => m.SessionsModule),
  },
  { path: '**', redirectTo: 'landing' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
