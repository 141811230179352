import { Component, Input, OnChanges } from '@angular/core';
import { FlightSearchService } from 'src/app/routes/crew/services/flight-search.service';
import { FlightSearchResult } from '../../../models/flight-search-result';

@Component({
  selector: 'app-flight-search-results',
  templateUrl: './flight-search-results.component.html',
  styleUrls: ['./flight-search-results.component.scss'],
  providers: [FlightSearchService],
})
export class FlightSearchResultsComponent implements OnChanges {
  searchResults: FlightSearchResult[];

  @Input() flightNum = '';
  @Input() date = '';
  @Input() depStation = '';
  @Input() arrStation = '';

  constructor(private flightSearchService: FlightSearchService) {}

  ngOnChanges() {
    this.searchResults = [];
    this.flightSearchService
      .getFlights(
        this.date,
        this.flightNum !== '' ? this.flightNum : null,
        this.depStation !== '' ? this.depStation : null,
        this.arrStation !== '' ? this.arrStation : null
      )
      .subscribe((data) => {
        this.searchResults = data;
        if (data.length < 1) {
          alert('No Results');
        }
      });
  }
}
