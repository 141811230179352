import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-flight-info',
  templateUrl: './flight-info.component.html',
  styleUrls: ['./flight-info.component.scss'],
})
export class FlightInfoComponent implements OnInit {
  /**
   * Flightduty definition
   */
  @Input() flightDuty: any = {};

  @Output() onClickFlightDutyData = new EventEmitter<boolean>();

  /**
   * Layover definition
   */

  @Input() title = '';

  @Input() layoverData: any = {};

  @Input() flightLeg: any = {};

  @Output() onClickLayoverData = new EventEmitter<boolean>();

  ngOnInit(): void {
    if (
      this.flightDuty?.startDateTime?.localTime &&
      this.checkIsToday(this.flightDuty?.startDateTime?.localTime)
    )
      this.flightDuty.isFlightDutyOpen = true;
  }

  /**
   * On click flight data
   */
  onClickFlightData() {
    this.onClickFlightDutyData.emit(
      (this.flightDuty.isFlightDutyOpen = !this.flightDuty.isFlightDutyOpen)
    );
  }

  /**
   * On click layover
   */
  onClickLayover() {
    this.onClickLayoverData.emit(
      (this.flightLeg.isLayoverOpen = !this.flightLeg.isLayoverOpen)
    );
  }

  /**
   * Check date is today
   * @param date
   * @returns
   */
  checkIsToday(date: string) {
    const currDate = new Date();
    const flightDate = new Date(date);
    const returnCondition =
      currDate.getDate() === flightDate.getDate() &&
      currDate.getFullYear() === flightDate.getFullYear() &&
      currDate.getMonth() === flightDate.getMonth();
    return returnCondition;
  }
}
