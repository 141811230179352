import { Injectable } from '@angular/core';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { User, UserInfo } from 'src/app/models/user';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/shared/services/http.services';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import * as UserSessionActions from 'src/app/shared/store/actions/user-session.actions';
import { getUserState } from 'src/app/shared/store/reducers';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn$: Observable<boolean>;

  userObs: Observable<UserState> = this.store.select(getUserState);

  user: User = new User();

  constructor(
    public authService: PingAuthenticationService,
    private store: Store<{ user: UserState }>,
    private httpService: HttpService
  ) {
    this.loggedIn$ = this.authService.identity$.pipe(map((l) => !!l));
    this.authService.identity$
      .pipe(
        skipWhile((value) => !value),
        take(1)
      )
      .subscribe((value) => {
        if (value.access_token) {
          sessionStorage.setItem('pingFederateToken', value.access_token);
          this.getUserInfoFromPingFederateToken(value.access_token);
        }
      });
  }

  /**
   * Get token and parse user information
   * @param accessToken
   */
  getUserInfoFromPingFederateToken(accessToken: string): void {
    try {
      const id: UserInfo = JSON.parse(atob(accessToken.split('.')[1]));
      const userInfo: UserInfo = new UserInfo(id);
      this.store.dispatch(UserSessionActions.loginAction({ userInfo }));
      this.userObs.subscribe({
        next: (userObs) => {
          this.user = userObs.user;
        },
      });
    } catch (err) {
      // Will redirect to Un Authorized page
    }
  }

  public createLoginRequest(user: User): any {
    const loginRequest: any = {
      airlineCode: user.airlineCode,
      employeeLogin: user.employeeNumber,
      appSessionId: 'browser',
      siteMinderEmployeeId: user.employeeNumber,
    };
    return loginRequest;
  }

  getLoginDetails(userInfo: User) {
    const url = `${environment.apiBaseURL}/v1/getLoginDetails`;
    return this.httpService.postService(url, this.createLoginRequest(userInfo));
  }

  /**
   * Check user is admin
   * return this.user?.isAdmin ?? false;
   * @returns
   */
  isAdminUser(): boolean {
    return this.user?.isAdmin ?? false;
  }
}
