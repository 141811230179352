import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';

import { httpInterceptorProviders } from './http-interceptor/interceptor';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { SharedDialogComponent } from './components/dialog/dialog.component';
import { LoaderComponentComponent } from './components/loader-component/loader-component.component';
@NgModule({
  declarations: [
    ErrorCodeComponent,
    SharedDialogComponent,
    LoaderComponentComponent,
  ],
  imports: [CommonModule, MaterialModule, RouterModule],
  exports: [
    CommonModule,
    RouterModule,
    ErrorCodeComponent,
    SharedDialogComponent,
    LoaderComponentComponent,
  ],
  providers: [httpInterceptorProviders],
})
export class SharedModule {}
