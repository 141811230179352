import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PingAuthGuard } from '@techops-ui/ping-authentication';
import { AdminAuthGuard } from 'src/app/routes/admin/admin-auth.guards';
import { ConfigListComponent } from './components/config-list/config-list.component';
import { UserListComponent } from './components/user-list/user-list.component';

const routes: Routes = [
  {
    path: 'users',
    component: UserListComponent,
    canActivate: [AdminAuthGuard, PingAuthGuard],
  },
  {
    path: 'config',
    component: ConfigListComponent,
    canActivate: [AdminAuthGuard, PingAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
