import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {
  SequenceDetailsService,
  SequenceOverviewDetails,
} from '../../../services/sequence-details.service';
import { FlightSearchResult } from '../../../models/flight-search-result';
import { GroupFlightsArray } from '../../../models/group-array-result';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sequence-overview',
  templateUrl: './sequence-overview.component.html',
  styleUrls: ['./sequence-overview.component.scss'],
})
export class SequenceOverviewComponent implements OnInit {
  constructor(
    private serviceDetails: SequenceDetailsService,
    private router: Router
  ) {}

  flightResult: SequenceOverviewDetails;
  flightSequenceResult: FlightSearchResult[] = [];
  groupFlights: GroupFlightsArray[] = [];
  isToday = true;
  todaysDate: string = moment().format('YYYY-MM-DD');

  ngOnInit() {
    this.serviceDetails.getFlights().subscribe((results) => {
      if (results) {
        this.flightResult = results;
        this.flightSequenceResult = this.flightResult.flightDuty || [];

        const data = new Set(
          this.flightSequenceResult.map((item) => item.flightDate)
        );
        data.forEach((date) => {
          const departureDate = date;
          if (departureDate === this.todaysDate) {
            this.isToday = true;
          } else {
            this.isToday = false;
          }
          this.groupFlights.push({
            date: date,
            isToday: this.isToday,
            flights: this.flightSequenceResult.filter(
              (i) => i.flightDate === date
            ),
          });
        });
      } else {
        alert('No Sequence Returned');
        this.router.navigate(['/landing']);
      }
    });
  }
}
