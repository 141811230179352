<span class="top-user-list">
  <button
    mat-raised-button
    (click)="onCreateUserClick()"
    color="primary"
    class="create-user-button"
  >
    Create a User
  </button>
</span>
<div [hidden]="isLoaderActive" class="userlist">
  <div class="content-size">
    <mat-grid-list cols="2" rowHeight="fit" gutterSize="20px">
      <table mat-table mat-sort [dataSource]="userData" matSort>
        <!-- EmployeeId Column -->
        <ng-container matColumnDef="employeeId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee ID</th>
          <td mat-cell *matCellDef="let element">{{ element.employeeId }}</td>
        </ng-container>

        <!-- Firstname Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
          <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        </ng-container>

        <!-- Lastname Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
          <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        </ng-container>

        <!-- Isactive Column -->
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [disabled]="true"
              [(ngModel)]="element.active"
            ></mat-checkbox>
          </td>
        </ng-container>

        <!-- Business unit Column -->
        <ng-container matColumnDef="businessUnit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Business Unit
          </th>
          <td mat-cell *matCellDef="let element">{{ element.businessUnit }}</td>
        </ng-container>

        <!-- Updated by Column -->
        <ng-container matColumnDef="updatedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated By</th>
          <td mat-cell *matCellDef="let element">{{ element.updatedBy }}</td>
        </ng-container>

        <!-- Updated Date Column -->
        <ng-container matColumnDef="lastUpdatedDateTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Updated Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.lastUpdatedDateTime | date: 'MM/dd/yyyy HH:mm' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <span class="button-container">
              <span
                (click)="onEditUserClick(element)"
                class="material-icons user-list-edit-btn"
              >
                edit
              </span>
              <span
                (click)="onDeleteClick(element)"
                class="material-icons user-list-delete-btn"
              >
                delete
              </span>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="7">
            No Admin Users to Display
          </td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 25, 50]"
        showFirstLastButtons
        aria-label="Select page of users"
      ></mat-paginator>
    </mat-grid-list>
  </div>
</div>
